
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import { connect } from "react-redux"; 
import PageWrapper from "../components/PageWrapper";
import { Field, PhoneField, DateField } from "../components/forms/Field";
import { 
    togglePreloader,
    showFetchError,
} from "../actions/index";

class EditProfileContainer extends Component {
    constructor ( props ) {
        super( props );

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            emailValid: null,
            phone: "",
            organizationName: "",
            formValid: true,
            password: "",
            passwordRetype: "",
            passwordsValid: true,
            passwordValidationMessage: "",
            phoneExtension: "",
            bio: "",
            location: "",
            birthday: null, 

            submissionSuccess: false,
        };

        this.changeUserProfileFieldEventNew = this.changeUserProfileFieldEventNew.bind(this);
        this.handleUserProfileSubmit = this.handleUserProfileSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.renderSubmissionPage = this.renderSubmissionPage.bind(this);
        this.renderThankYouPage = this.renderThankYouPage.bind(this);
        this.handleBackToUpdateProfile = this.handleBackToUpdateProfile.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0);

        this.props.togglePreloader(true);
        let profileId = this.props.userProfileData.userId;
        
        apiRequest( getRoute('updateUserProfile',[profileId]))
        .then(result => {
            this.props.togglePreloader(false);

            this.setState({
                firstName: result.profile.first_name ? result.profile.first_name : "",
                firstNameValid: true,
                lastName: result.profile.last_name ? result.profile.last_name : "",
                lastNameValid: true,
                email: result.profile.email ? result.profile.email : "",
                emailValid: true,
                phone: result.profile.phone ?  result.profile.phone : "",
                phoneValid: true,
                organizationName: result.profile.organization_name ? result.profile.organization_name : "",
                phoneExtension: result.profile.phone_extension ? result.profile.phone_extension : "",
                bio: result.profile.bio ? result.profile.bio : "",
                location: result.profile.location ? result.profile.location : "",
                birthday: result.profile.birth_date ? result.profile.birth_date : null,
            });
        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
        })
    }

    componentDidMount(){

    }

    validateForm() {
        let formValid = true;

        if( this.state.email === "" ) {
            formValid = false;
        }

        if(this.state.password && this.state.password.length ) {
            if( this.state.password !== this.state.passwordRetype ) {
                //passwordsValid = false;
                formValid = false;
                this.setState({ passwordValidationMessage: "Passwords do not match", passwordsValid: false });
            } else {
                this.setState({ passwordValidationMessage: "", passwordsValid: true });
            }
        }

        return formValid;
    }

    changeUserProfileFieldEventNew(value, field){
        switch(field) {
            case "first_name":
            this.setState({firstName:value});
            value !== "" ? 
            this.setState({firstName:value, firstNameValid:true}) : 
            this.setState({firstName:value, firstNameValid:false}) 
            break;
            case "last_name":
            value !== "" ? 
            this.setState({lastName:value, lastNameValid:true}) : 
            this.setState({lastName:value, lastNameValid:false}) 
            break;
            case "email":
            value !== "" ? 
            this.setState({email:value, emailValid:true}) : 
            this.setState({email:value, emailValid:false}) 
            break;
            case "phone":
            value !== "" ? 
            this.setState({phone:value, phoneValid:true}) : 
            this.setState({phone:value, phoneValid:false}) 
            break;
            case "phone_extensions":
            this.setState({phoneExtension:value})
            break;
            case "bio":
            this.setState({bio:value})
            break;
            case "location":
            this.setState({location:value})
            break;
            case "birthday":
            this.setState({birthday:value})
            break;
            case "organization_name":
            this.setState({organizationName:value})
            break;
            case "reset_password":
            this.setState({password:value})
            break;
            case "reset_password_retype":
            this.setState({passwordRetype:value})
            break;

            default:
            console.log( "An unknown field type was used" );
            break;
        }
    }

    handleUserProfileSubmit(event) {
        event.preventDefault();
        let isValid = this.validateForm();

        if( isValid ) {
            var profileId = this.props.userProfileData.userId;
            var cleanPhone = this.state.phone.replace( /-/g, "" );

            var data = {
                'first_name': this.state.firstName,
                'last_name': this.state.lastName,
                'email': this.state.email,
                'phone': cleanPhone,
                'organization_name': this.state.organizationName,
                'phone_extension': this.state.phoneExtension,
                'location': this.state.location,
                'birth_date': this.state.birthday,
                'bio': this.state.bio,
            };

            if( this.state.password && this.state.password.length && this.state.passwordsValid ) {
                data.password = this.state.password;
                data.password_repeat = this.state.password;
            }

            //console.log("form good to go");
            this.props.togglePreloader(true);
            
            apiRequest( getRoute('updateUserProfile',[profileId]), {
                method: 'POST',
                body: JSON.stringify(data)
              })
            .then(result => {
                this.props.togglePreloader(false);
                //console.log("profile updated!");
                this.setState({submissionSuccess: true});
            })
            .catch( (error) => {
                this.setState({ passwordValidationMessage: error.badRequest.password[0], passwordsValid: false  });
                //console.log( 'Error', error.badRequest.password );
                this.props.togglePreloader(false); 
            })
        }
    }

    renderSubmissionPage() {
        let submitButton = <input className="report-btn" type="submit" name="Submit" value="Submit" />;
        //console.log(this.state);
        if( this.state.birthday === "" ) {
            this.setState({ birthday: null });
        }

        return(
            <PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle('Edit Profile') }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching user profile data" />
                
                <h1 id="main-heading" className="page-title">Edit Profile</h1>
                
                <form id="user-profile-data" onSubmit={this.handleUserProfileSubmit}>

                    <Field 
                        name="first_name" 
                        id="user-profile-data-first-name" 
                        label="First Name" 
                        type="text" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.firstName}
                        defaultValue={this.state.firstName}
                        validationMessage={""} />
                    

                    <Field 
                        name="last_name" 
                        id="user-profile-data-last-name" 
                        label="Last Name" 
                        type="text" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.lastName}
                        defaultValue={this.state.lastName}
                        validationMessage={""} />

                    <Field 
                        name="email" 
                        id="user-profile-data-email" 
                        label="Email" 
                        type="email" 
                        required={true} 
                        isValid={this.state.emailValid} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.email}
                        defaultValue={this.state.email}
                        validationMessage={"Please enter your email"}
                        maxLength={60}
                    />


                    <PhoneField 
                        name="phone" 
                        id="user-profile-data-phone" 
                        label="Phone" 
                        type="phone" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.phone}
                        defaultValue={null}
                        mask="999-999-9999"
                        validationMessage={"Please enter your phone number"} />

                    <Field 
                        name="phone_extensions" 
                        id="user-profile-data-phone-extension" 
                        label="Phone ext." 
                        type="text" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.phoneExtension}
                        defaultValue={this.state.phoneExtension}
                        validationMessage={""} />    

                    <Field 
                        name="bio" 
                        id="user-profile-bio" 
                        label="Bio" 
                        type="textarea2" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.bio}
                        defaultValue={this.state.bio ? this.state.bio : ""}
                        validationMessage={""} />

                    <Field 
                        name="location" 
                        id="user-profile-location" 
                        label="Location" 
                        type="text" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.location}
                        defaultValue={this.state.location}
                        validationMessage={""} /> 

                    <DateField  
                        name="birthday" 
                        id="user-profile-birthday" 
                        label="Birth Date (yyyy-mm-dd)" 
                        type="date" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.birthday}
                        defaultValue={null}
                        mask="9999-99-99"
                        validationMessage={""} />        
                    
                    <Field 
                        name="organization_name" 
                        id="user-profile-organization_name" 
                        label="Organization Name" 
                        type="text" 
                        required={false} 
                        isValid={true} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        value={this.state.organizationName}
                        defaultValue={this.state.organizationName}
                        validationMessage={""} />

                    <Field 
                        name="reset_password" 
                        label="Reset Password" 
                        type="password" 
                        isValid={ this.state.passwordsValid } 
                        required={false} 
                        onChange={ this.changeUserProfileFieldEventNew } 
                        validationMessage={ this.state.passwordValidationMessage } 
                    />

                    <Field 
                        name="reset_password_retype" 
                        label="Retype Password" 
                        type="password" 
                        isValid={ this.state.passwordsValid } 
                        required={false} 
                        onChange={ this.changeUserProfileFieldEventNew }
                        validationMessage={ this.state.passwordValidationMessage } 
                    />    

                    {submitButton}

                </form>
            </PageWrapper>
        )
    }

    renderThankYouPage() {
        return (
            <PageWrapper>
                <h2>Success!</h2>
                <p>Your profile has been updated.</p>
                <p><button onClick={ this.handleBackToUpdateProfile }>Back</button></p>
            </PageWrapper>
        )
    }

    handleBackToUpdateProfile() {
        this.setState({ submissionSuccess: false });
    }

	render() {
        if( this.state.submissionSuccess ) {
            return this.renderThankYouPage();
        } else {
            return this.renderSubmissionPage();
        }
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
        isLoggedIn: state.auth.isLoggedIn,
        userProfileData: state.auth,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
	}, dispatch)	
}

export default connect( mapStateToProps, matchDispatchToProps )( EditProfileContainer ); 