import React from "react";
import store from "../Store"; 


// Base url to the API
const API_URL = "http://167.99.191.66:8080/";
//const API_URL = "http://192.168.1.148:8000/";

// Default format used by the application
const DEFAULT_FORMAT = 'json'; 


// Available routes
// Define routes by a unique key and then the url they will use
// Variables pased in by an array in pathArgs will be parsed into the url in order
// eg "some-url/{0}/secondary/{1}/"" when passing getUrl('myurl', [123,321]); will be turned into 
// "http://138.197.174.91/api/web/some-url/123/secondary/321/"
// get variables can be passed in by a key value object
// eg "some-url/{0}/secondary/{1}/"" when passing getUrl('myurl', [123,321], {query:'test'}); will be turned into 
// "http://138.197.174.91/api/web/some-url/123/secondary/321/?query=test"

const ROUTES = {
	gaugeListing : 'api/web/gauges', 
	gaugeDetails: 	'api/web/gauge/{0}',
	standardReportBuilder: 'api/web/report_builder',
	reportBuilder: 'api/web/report_builder',  // http://138.197.174.91/api/web/report_builder - web/custom_report_builder?gauge_pks=1,2,3,4,5,6&datetime_of_measurement_range_0=2017-11-16+05:00:00&datetime_of_measurement_range_1=2017-11-23+05:00:00&format=json
	dataExplorerStations: 'api/web/data_explorer/stations',
	dateExplorerSubNetworks: 'api/web/data_explorer/get_unique_sub_networks',
	dataRequests: 'api/data_request',
	login: 'api/login',
	resetPasswprd: 'api/send-reset-password-email',
	doResetPassword: 'api/reset-password',
	register: 'api/register',
	activateAccount: 'api/activate',
	userInfo: 'api/profile/{0}/',
	documents: 'api/document_categories',
	allStationsAndGauges: 'api/web/report_builder/get_station_and_gauge_names',
	uniqueStationName: 'api/web/stations/get_unique_station_names',
	reportBuilderDataRange: 'api/time_series_data/get_range',
	latestTweet: 'api/tweets/get_latest',
	damOpsAvailableStorage: "api/dam_ops/g_ross_lord_dam/get_available_storage_capacity",
	damOpsOutflowDischarge: "api/dam_ops/g_ross_lord_dam/get_forcasted_outflow_discharge",
	damOpsStatusInfo: "api/dam_ops/g_ross_lord_dam/get_status_info",
	damOpsOperationLevel: "api/dam_ops/g_ross_lord_dam/get_recommended_operations_level",
	stationAndGaugeNames: "api/web/report_builder/get_station_and_gauge_names",
	currentGateOpenings: "api/current_gate_openings", // /api/current_gate_openings?format=json&station_id=16
	weatherAlerts: 'api/web/environment_canada_regional_weather_alert_feeds',  //http://138.197.174.91//api/web/environment_canada_regional_weather_alert_feeds?region=City+of+Toronto
	weatherForecast: 'api/web/environment_canada_city_weather_feeds', // http://138.197.174.91/api/web/environment_canada_city_weather_feeds?city=Toronto
	damOpsLogs: 'api/dam_operation_logs',
	damOpsStations: "api/stations",
	stationDetails: "api/station/{0}",
	damSafetyCodes: "api/dam_safety_codes",
	damSafetyCodeLogs: "api/dam_safety_code_logs",
	damOpsSummary: "api/dam_operation_summary",
	setDamSafetyCode: "api/set_dam_safety_code",
	getDashboards: "api/dashboards",
	getDashboard: 'api/dashboard/{0}/',
	batteryGauges: 'api/battery-gauges',
	pages: 'api/page/{0}/',
	faq: 'api/faqs',
	feedback: 'api/feedback',
	getPrecipSums: 'api/time_series_data/get_sum',	
	updateUserProfile: 'api/profile/{0}/', 
	precipTotals: 'api/web/precip-distributions',
	cameraViews: 'api/camera-views',
}

export function getRoute(name, pathArgs=[], getArgs={}){
	var route = ROUTES[ name ];

	if( route === undefined ){
		throw new Error("Cannot find specified path!: " + name);  
	}

	//convert pathArgs to array if needed
	if(typeof pathArgs === "string"){
		pathArgs = [pathArgs];
	}


	//check if there are any pathArgs to be matched
	var  match = route.match(/{\d+}/);

	if(!pathArgs.length && match !== null){
		throw new Error("Route that requires path variables called but no pathArgs passed!: " + route); 
	}

	//look for and replcae pathArgs in the url
	for (var i = 0; i < pathArgs.length; i++) {
		var searchvalue = '{' + i + '}';
		if(route.indexOf(searchvalue) === -1){
			throw new Error("pathArgs passed but not found in url: " + route); 
		}
		else{
			route = route.replace(searchvalue, pathArgs[i]);
		}

	};

	//check if there are any left after
	match = route.match(/{\d+}/);
	if(match !== null){
		throw new Error("missing pathArgs for route!: " + route); 
	}

	if( getArgs.format === undefined ){
		getArgs["format"] = DEFAULT_FORMAT;
	}


	// Add the Get variables to the url
	var getKeysArray = [];
	var getKeys = Object.keys(getArgs);
	for (i = 0; i < getKeys.length; i++) {
		getKeysArray.push(`${getKeys[i]}=${getArgs[getKeys[i]]}`);
	}

	var theRoute = API_URL + route;
	if(getKeysArray.length){
		theRoute = theRoute + '?' + getKeysArray.join('&');
	}

	return theRoute;
}

export default getRoute;


// returns the deep nested is logged in
function selectToken(state) {
  return state.auth.token
}


/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(response) {
  return new Promise((resolve, reject) => response.json()
	    .then((json) => resolve({
	      status: response.status,
	      statusText: response.statusText,
	      ok: response.ok,
	      json,
	    }))
	    .catch((error) => {
	    	// there was an error parsing the json
	    	reject(error)
	    })
    );
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise

	There are a couple of standard catches
	networkError => There was an error communicating with the server, 
					will contain a networkError property with the error
	badRequest	 => Server returned a resonse stating that it was a bad request
					will contain badRequest property containing the error returned from the server


 */


export function apiRequest(url, options={}) {
	let currentToken = selectToken(store.getState());

	let newOptions = {...options};

	if( !newOptions.headers ){
		newOptions.headers = new Headers();
	}
	else if( typeof newOptions.headers.set !== 'function' ){
		//parse into a Headers object
		newOptions.headers = new Headers(newOptions.headers);
	}

	//always use these for our API
	newOptions.headers.set('Accept','application/json');
	newOptions.headers.set('Content-Type','application/json');

	//if the current token is set add the authorization
	if( currentToken !== null ){
		newOptions.headers.set('Authorization', `Token ${currentToken}`);
	}

	return new Promise((resolve, reject) => {
		fetch(url, newOptions)
			.then(parseJSON)
			.then((response) => {
				if( response.statusText === "Created" ){
					// special case if the status text is created then return the status case
					// the api simply sets created when succesfully created
					return resolve({statusText: 'Created', json: response.json})
				}
				else if (response.ok) {
					return resolve(response.json);
				}
 
				// extract the error from the server's json
				return reject({
					badRequest: response.json,
					statusText: response.statusText,
					status: response.status
				});
			})
			.catch((error) => reject({
				networkError: error.message,
			}));
	});
}

// Convers django errors to state errors
/*
	INPUTS:
	fields expects format:
	 {
		first_name: {
			value: '',
			validationMessage: '',
			isValid: false,
		},
		last_name: {
			value: '',
			validationMessage: '',
			isValid: false,
		},
	}
	error is the generic error results from django

	OUTPUT:
	returns an object:
	{
		registerFields: // a newly formated state with the set validation messages
		registerError  // a generic error message if non field error are set
	}

*/
export function mapDjangoErrorsToFieldState(fields, error){
	// the server returned an error
	//console.log('register bad request');

	let newFields = {};
	let newState = {};
	
	const stateKeys = Object.keys(fields);
	//build a new state
	for (var i = 0; i < stateKeys.length; i++) {
		let inputState = {...fields[ stateKeys[i] ] };

		if( error.badRequest[  stateKeys[i] ] !== undefined ){
			let serverMessage = error.badRequest[ stateKeys[i] ];

			//console.log(serverMessage);

			inputState.isValid = false;
			if( Array.isArray( serverMessage) ){
				inputState.validationMessage = serverMessage.map( (item) => { return <span className="validation-message">{item}</span> } );
			}
			else{
				inputState.validationMessage = serverMessage;
			}
		}
		newFields[ stateKeys[i] ] = inputState;
	}
	newState.fields = newFields;

	newState.generalErrorMessages = '';
	if( error.badRequest.non_field_errors ){
		newState.generalErrorMessages =  error.badRequest.non_field_errors;
	}

	return newState;
}