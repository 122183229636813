import React, { Component } from "react";
import leftPad from "left-pad";

class RadarMapLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {
			addRadarLegend: false,
            radarLegendData: null,
            lastRadarUpdate: null,
		};

        this.fetchRadarImageLegend = this.fetchRadarImageLegend.bind(this);
        this.setupRadarImageLegend = this.setupRadarImageLegend.bind(this);
        this.getLastUpdate = this.getLastUpdate.bind(this);
    }

    componentDidMount() {
        this.fetchRadarImageLegend();
        this.getLastUpdate();
    }

    componentWillReceiveProps( nextProps ) {
        this.getLastUpdate();
    }

    fetchRadarImageLegend() {
		fetch("https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer/legend?f=pjson")
		.then(response => response.json())
        .then(data => {
			this.setState({ radarLegendData: data.layers[2].legend }); 
        });
    }
    
    getLastUpdate() {
        fetch("https://nowcoast.noaa.gov/layerinfo?request=timestamps&service=radar_meteo_imagery_nexrad_time&layers=0&displaytime=now&format=json")
		.then(response => response.json())
        .then(data => {
            //console.log(data);
            for( var i=0; i < data.layers[0].timestamps.length; i++ ){
                if( data.layers[0].timestamps[i].label === "CONUS" ) {
                    this.setState({ lastRadarUpdate: data.layers[0].timestamps[i].validtime }); 
                }
            }
			
        });
    }

    setupRadarImageLegend( data ) {
        let imageObjAry = [];

        for( var i=0; i < data.length; i++ ) {
            var obj = {};

            switch(data[i].label) {
                case "5.000000001 - 5.5":
                obj.imageData = data[i].imageData;
                obj.label = "05 DBZ";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "10.00000001 - 10.5":
                obj.imageData = data[i].imageData;
                obj.label = "10";
                obj.secondaryLabel = "Light";
                imageObjAry.push(obj);
                break;

                case "15.00000001 - 15.5":
                obj.imageData = data[i].imageData;
                obj.label = "15";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "20.00000001 - 20.5":
                obj.imageData = data[i].imageData;
                obj.label = "20";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "25.00000001 - 25.5":
                obj.imageData = data[i].imageData;
                obj.label = "25";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "30.00000001 - 30.5":
                obj.imageData = data[i].imageData;
                obj.label = "30";
                obj.secondaryLabel = "Moderate";
                imageObjAry.push(obj);
                break;

                case "35.50000001 - 36":
                obj.imageData = data[i].imageData;
                obj.label = "35";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "40.00000001 - 40.5":
                obj.imageData = data[i].imageData;
                obj.label = "40";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "45.00000001 - 45.5":
                obj.imageData = data[i].imageData;
                obj.label = "45";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "50.00000001 - 50.5":
                obj.imageData = data[i].imageData;
                obj.label = "50";
                obj.secondaryLabel = "Heavy";
                imageObjAry.push(obj);
                break;

                case "55.00000001 - 55.5":
                obj.imageData = data[i].imageData;
                obj.label = "55";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "60.00000001 - 60.5":
                obj.imageData = data[i].imageData;
                obj.label = "60";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "65.50000001 - 66":
                obj.imageData = data[i].imageData;
                obj.label = "65";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                case "70.00000001 - 71":
                obj.imageData = data[i].imageData;
                obj.label = "70";
                obj.secondaryLabel = "Extreme";
                imageObjAry.push(obj);
                break;

                case "75.00000001 - 76":
                obj.imageData = data[i].imageData;
                obj.label = "75";
                obj.secondaryLabel = "";
                imageObjAry.push(obj);
                break;

                default:
                break;
            }
        }

		let legend = imageObjAry.map(( item, index ) => {
            var imageSrc = "data:" + item.contentType + ";base64, " + item.imageData;
            return( 
                <li key={index} >
                    <img src={imageSrc} alt=""/>
                    <span>{item.label}</span>
                </li> )
        }); 

		return legend;
	}
    
    render() {
        let lastUpdated = null;

        //console.log(this.props); 

        if( this.state.lastRadarUpdate ) {
            
            //let rawTime = this.props.radarMapLastUpdated;
            let rawTime = new Date(this.state.lastRadarUpdate);

            //console.log(rawTime);

            let day = leftPad( rawTime.getDate(),2,0 );
            let month = leftPad( (rawTime.getMonth() + 1),2,0 );
            let year = leftPad( rawTime.getFullYear(),2,0 );
            let hour = leftPad( rawTime.getHours(),2,0 );
            let minutes = leftPad( rawTime.getMinutes(),2,0 );
            let seconds = leftPad( rawTime.getSeconds(),2,0 );

            lastUpdated = month + "/" + day + "/" + year + " " + hour + ":" + minutes + ":" + seconds; 
        }
        
        let radarMapLegend = null;

		if( this.state.radarLegendData && this.props.radarCheckboxStatus === "on"  ) {
			radarMapLegend = this.setupRadarImageLegend( this.state.radarLegendData );
		}

        return (
            <div className="weather-radar-map-legend">
                <ul>
                    { radarMapLegend }
                    <li>Last updated: <br/>{ lastUpdated }</li>
                </ul> 
            </div>
        );
    }
};

    export default RadarMapLegend;

 