import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Preloader from "../components/Preloader";
import {getRoute, apiRequest} from "../helpers/Api";
import { 
	togglePreloader,

} from "../actions/index";
	
class SiteInformationContainer extends Component {
	constructor({props}) {
        super(props);

        this.getpageData = this.getpageData.bind(this);

        this.state = {
            pageData: null,
            isforohfor: false
        };
	}

	componentDidMount(){ 
        this.getpageData( this.props.match.params.info );
    }

    componentWillReceiveProps(nextProps) {
        if( this.props.match.params.info !== nextProps.match.params.info ) {
            this.getpageData( nextProps.match.params.info );
        }
        if( this.props.isLoggedIn !== nextProps.isLoggedIn){
            this.getpageData( nextProps.match.params.info );
        }
    }

    getpageData(slug) {
        this.props.togglePreloader(true);
        //this.setState({isforohfor: false});
        apiRequest( getRoute('pages', [ slug ])) 
		.then(result => {
            this.props.togglePreloader(false);
            this.setState({ pageData: result });
        })
        .catch( (error) => {
            //console.log(error.status);
            if( error.status === 403 ){ 
                this.props.doAuthCheck();
                return error;
            }
			this.props.togglePreloader(false); 

            this.setState({isforohfor: true});

            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
    }

	render() {
        if( this.state.pageData ) {
            let data = this.state.pageData;

            let content = null;

            if( data.require_login == true && this.props.isLoggedIn == false ){
                content = <div>You must be logged in to view this page.</div>
            }
            else{
                content = <div dangerouslySetInnerHTML={{ __html: data.content }} ></div>
            }


            return ( 
                <div className="site-info-page-content-container" role="main">
                    <Helmet>
                        <title>{ data.page_title }</title>
                        <meta name="description" content={ data.meta_description_tags } />
                    </Helmet>
                    
                    <div className="container-fluid">
                        <h1 id="main-heading">{ data.page_title }</h1>
                        {content}
                    </div>
                    
                    <Preloader showPreloader={ this.props.handleTogglePreloader } message="Getting page data" />
                </div>	
            )
        } else if( this.state.isforohfor === true ){
            return (

                <div className="site-info-page-content-container" role="main">
                    <Helmet>
                        <title>Page not found</title>
                    </Helmet>
                    
                    <div className="container-fluid">
                        <h1 id="main-heading">Page not found</h1>
                        <p>We couldn't find the page you are looking for.</p>
                    </div>
                    
                    <Preloader showPreloader={ this.props.handleTogglePreloader } message="Getting page data" />
                </div>  

            );
        }
        else{
            return  <Preloader showPreloader={ this.props.handleTogglePreloader } message="Getting page data" />;
        }
	}
}

function mapStateToProps(state) {
	return { 
		handleTogglePreloader: state.togglePreloader,
        isLoggedIn: state.auth.isLoggedIn
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		togglePreloader: togglePreloader,
	 }, dispatch)
}

export default connect( mapStateToProps, matchDispatchToProps )( SiteInformationContainer ); 