import React, { Component } from "react";
import PageWrapper from "../components/PageWrapper";
//import Register from "../components/auth/Register";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoginModal } from "../actions/Auth";
import { getRoute, apiRequest } from "../helpers/Api";
import Field from "../components/forms/Field";
//import parseLoginApiData from "../../helpers/Auth";

class ResetPassword extends Component {
    constructor ( props ) {
        super( props );

        this.onSubmitError = this.onSubmitError.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            scrollToError: false,
            password: {
                value: '',
                validationMessage: '',
                isValid: true,
            },
            password_repeat: {
                value: '',
                validationMessage: '',
                isValid: true,
            },
            passwordResetSuccessMsg: ''
        }
    }

    componentWillMount(){
        window.scrollTo(0, 0);
    }
 
    componentDidUpdate(){

        if( this.state.scrollToError === true ){
            const errors = document.getElementsByClassName('form-field-validation-error');
            if( errors.length ){
                errors[0].scrollIntoView();
            }
            this.setState({'scrollToError': false});
        }
    }

    onSubmitError(){
    	this.setState({'scrollToError': true});
    }

    inputChanged(value, name, event) {
        if( name === "reset-password" ) {
            this.setState({ 
                password: {
                    value: value,
                    validationMessage: '',
                    isValid: true,
                } 
            });
        }

        if( name === "reset-password-retype" ) {
            this.setState({ 
                password_repeat: {
                    value: value,
                    validationMessage: '',
                    isValid: true,
                }
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        const passwords = {
            password: this.state.password.value,
            password_repeat: this.state.password_repeat.value,
            pr_access_code: this.props.match.params.token
        };

        //console.log( this.state );

        apiRequest(getRoute('doResetPassword'), {
              method: 'POST',
              body: JSON.stringify(passwords)
            })
            .then(theResult => {
                //const result = theResult.json;
                //console.log('password reset successful ', theResult.details );
                this.setState({ passwordResetSuccessMsg: theResult.details });
                

            })
            .catch(error => {
                // eslint-disable-next-line
                
                let validationMsg = [];
                let errorMsg = null;
                let loginDetailMessage = null;

                //bad result
                if(error.badRequest){
                    // the server returned an error
                    //console.log('login bad request');
                    //console.log(error);
                    
                    if(error.badRequest.password) {
                        if(error.badRequest.password.length) {
                            errorMsg = error.badRequest.password.map((msg, index) => {
                                return(
                                    <div key={index}>{msg}</div> 
                                )    
                            });
                        }
                    }
                    
                    if(error.badRequest.non_field_errors) {
                        if(error.badRequest.non_field_errors.length) {
                            loginDetailMessage = error.badRequest.non_field_errors.map((msg, index) => {
                                return(
                                    <div key={index}>{msg}</div> 
                                )    
                            });
                        }
                    }

                    if( errorMsg ) {
                        validationMsg = [...errorMsg];
                    }

                    if( loginDetailMessage ) {
                        validationMsg = [...loginDetailMessage];
                    }

                    //console.log(validationMsg);


                    this.setState({
                        password: {
                            validationMessage: validationMsg,
                            isValid: false,
                            value: this.state.password.value
                        },
                        password_repeat: {
                            validationMessage: validationMsg,
                            isValid: false,
                            value: this.state.password_repeat.value
                        }
                    });
                }
            }
        );
    }

	render() {

        let resetPasswordContent = ( this.state.passwordResetSuccessMsg !== '' ) ?
        <div>{ this.state.passwordResetSuccessMsg + " "}<button className="btn-link" onClick={this.props.showLoginModal}>Login</button></div> :
        <form onSubmit={this.handleSubmit}>
            <Field 
                name="reset-password" 
                label="Password" 
                type="password" 
                isValid={this.state.password.isValid} 
                required={true} 
                onChange={ this.inputChanged } 
                validationMessage={this.state.password.validationMessage} 
            />
            <Field 
                name="reset-password-retype" 
                label="Retype Password" 
                type="password" 
                isValid={this.state.password_repeat.isValid} 
                required={true} 
                onChange={ this.inputChanged }
                validationMessage={this.state.password_repeat.validationMessage} 
            />
                
                <input type="submit" value="Set new password" disabled={this.state.fetchingLogin} />
        </form>

		return (
			<PageWrapper>
                <Helmet>
                    <title>{ pageTitle('Register Account') }</title>
                </Helmet>
				
				<section>
				    <h1 id="main-heading" className="page-title">Reset Password</h1>
                    { resetPasswordContent }
			    </section>
			</PageWrapper>
		)
   	}
}

function mapStateToProps(state) {
	return { 
        isLoggedIn: state.auth.isLoggedIn
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		showLoginModal : showLoginModal
    }, dispatch)    
}


export default withRouter( connect( mapStateToProps, matchDispatchToProps )( ResetPassword ) ); 