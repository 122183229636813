import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";

import SlideoutPanel from "../components/SlideoutPanel";
import ReportBuilderMenu from "../components/slideout-menu/ReportBuilderMenu";
import Modal from "../components/Modal";
import DayPicker, { DateUtils } from "react-day-picker";
import TimePicker from '../components/TimePicker';
import SelectGaugeDropdown from "../components/data-explorer/SelectGaugeDropdown";
import Preloader from "../components/Preloader";
import ErrorMsg from "../components/ErrorMsg";
import {generateReport, parseReportResultData} from "../helpers/reportBuilderHelpers";
import {getRoute, apiRequest} from "../helpers/Api";
import SvgIcon from "../components/SvgIcon";
import RadioButtonSeries from "../components/dashboard/RadioButtonSeries";
import { 
	toggleSlideoutPanel,
	updateSlideoutMenu,
	toggleModal,
	handleDateTimeInteraction,
	handleTimeValueChange,
	resetTimeValues,
	searchFilterInputChange,
	doShowSearchList,
	setReportTemplateData,
	togglePreloader,
	stationAddedToReport,
	allStationsAddedToReport,
	updateSelectedStationGaugesForModal,
	addStationGaugesToReport,
	stationRemovedFromReport,
	gaugeAddedToReport,
	gaugeRemovedFromReport,
	handleDateReset,
	throwValidationError,
	formValidated,
	handleSetReportResults,
	handleShowReportBuilderView,
	plotGraphsOnSeperateContainer,
	setupStationsAndGauges,
	addAllGaugesForSelectedStation,
	storeMasterStationList,
	setTimeIsValid,
	throwDateRangeError,
	throwSearchInputError,
	clearAllStationsFromMenu,
	setDateTimeToPresent,
	deselectGauges,
	selectGauges,
	setDateRangeForDayPicker,
	removeAllGaugesFromReport,
	showFetchError,
	showAlarmsForStandardDonReport,
	resetReportState,
	storeGeneratedReportUrl,
} from "../actions/index";

import 'react-day-picker/lib/style.css';
	
class ReportsContainer extends Component {
	constructor({props}) {
		super(props);

		this.doShowModal = null;
		this.gaugeType = "";
		this.searchTerm = "";

		this.handleSlideoutClick = this.handleSlideoutClick.bind(this);
		this.updateGaugeSelectionMenu = this.updateGaugeSelectionMenu.bind(this);
		this.handleAddGaugeToMenu = this.handleAddGaugeToMenu.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.searchUpdated = this.searchUpdated.bind(this);
		this.handleSearchListToggleClick = this.handleSearchListToggleClick.bind(this);
		this.getReportTemplateData = this.getReportTemplateData.bind(this);
		this.loadRelatedStationGauges = this.loadRelatedStationGauges.bind(this);
		this.handleRemoveStationClick = this.handleRemoveStationClick.bind(this);
		this.removeGaugeSelectionFromMenu = this.removeGaugeSelectionFromMenu.bind(this);
		this.addGaugeToReport = this.addGaugeToReport.bind(this);
		this.removeGaugeFromReport = this.removeGaugeFromReport.bind(this);
		this.addAllStationsGauges = this.addAllStationsGauges.bind(this);
		this.handleGenerateReportClick = this.handleGenerateReportClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleAddStationToMenu = this.handleAddStationToMenu.bind(this);
		this.addAllGaugesForSelectedStation = this.addAllGaugesForSelectedStation.bind(this);
		this.doRenderSearchInput = this.doRenderSearchInput.bind(this);
		this.handleTimeToPresentInputChange = this.handleTimeToPresentInputChange.bind(this);
		this.removeGauges = this.removeGauges.bind(this);
		this.addGauges = this.addGauges.bind( this );
		this.removeAllGauges = this.removeAllGauges.bind(this);
		this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
		this.doShowAlarmsToggle = this.doShowAlarmsToggle.bind(this);
		this.getInitialPageState = this.getInitialPageState.bind(this);

		this.state = { 
			modalOpen: false,
			stationDataFilter: 'all'
		};
	}

	componentDidMount(){ 
		window.scrollTo(0, 0);
		this.props.toggleSlideoutPanel(true);

		if( this.props.canAcesssThirdPartyData === true ){
			this.setState({stationDataFilter: 'all'});
		}
		else{
			this.setState({stationDataFilter: 'trca' });
		}

		//Defaults to stream template
		if( this.props.dateTimeSelections.selectedStandardReport !== "*/*/*/*/*/*/*/" ) {
			this.getInitialPageState();
		}
	}

	componentWillReceiveProps(nextProps){
		if( this.props.canAcccesStandardReports === true && nextProps.canAcccesStandardReports === false ){
			this.props.resetReportState();
			this.getInitialPageState();
		}
	}

	componentDidUpdate(prevProps, prevState){
		if( prevProps.canAcesssThirdPartyData != this.props.canAcesssThirdPartyData ){
			// transitioning between can accessing data
			if( this.props.canAcesssThirdPartyData === true ){
				this.setState({stationDataFilter: 'all'});
			}
			else{
				this.setState({stationDataFilter: 'trca' });
			}

		}
	}

	getInitialPageState(){
		this.props.togglePreloader(true);

		apiRequest(getRoute('allStationsAndGauges'))
		.then(result => {
			var gaugeAry = [];
			var stationId = 1000;
			
			for( var i = 0; i < result.length; i++ ) {
				var gauges = [];

				for( var j = 0; j < result[i].gauge.length; j++ ) {
					var gauge = {
						id: result[i].gauge[j].id,
						prettySensorName: result[i].gauge[j].pretty_sensor_name,
						sensorName: result[i].gauge[j].sensor_name,
						extraSearchTerms: result[i].gauge[j].extra_search_terms
					} 

					gauges.push(gauge); 
				}

				var obj = {
					stationName: result[i].station_name,
					id: stationId,
					gauges: gauges,
					type: "custom",
					isThirdParty: result[i].is_third_party
				};

				stationId++;

				gaugeAry.push(obj);
			};

			//console.log(gaugeAry);
			this.props.setupStationsAndGauges( gaugeAry );
			this.props.storeMasterStationList( gaugeAry )
			this.props.togglePreloader(false);
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 

			/*let errorMsg = "";
			switch(error) {
				default:
				errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
				break;
			}
			this.props.togglePreloader(false); 
			var obj = {
				isVisible: true,
				message: errorMsg,
			};
			this.props.showFetchError( obj );*/
		})

		apiRequest(getRoute('reportBuilderDataRange'))
		.then(result => {
			// eslint-disable-next-line 
			var earliestDate = result.earliest.datetime_of_measurement.split("T");
			var dateFormated = earliestDate[0].split("-");
			var earlyDate = new Date(dateFormated[0], dateFormated[1] - 1, dateFormated[2]);

			var latestDate = result.latest.datetime_of_measurement.split("T");
			var lateDateFormated = latestDate[0].split("-");
			var lateDate = new Date(lateDateFormated[0], lateDateFormated[1] - 1, lateDateFormated[2]);

			var obj = {
				earliestDate: earlyDate,
				latestDate: lateDate,
			}; 

			this.props.setDateRangeForDayPicker( obj );
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 
			/*let errorMsg = "";
			switch(error) {
				default:
				errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
				break;
			}
			this.props.togglePreloader(false);
			var obj = {
				isVisible: true,
				message: errorMsg,
			};

			this.props.showFetchError( obj );*/
		})
	}

	handleSlideoutClick() {
		this.props.handleToggleSlideoutPanel ? 
		this.props.toggleSlideoutPanel(false) : this.props.toggleSlideoutPanel(true)
	}

	updateGaugeSelectionMenu(type, action) {
		//Gauge selected from search list then added to slideout menu
		var payload = {
			type: type,
			action: action
		};
		
		this.props.updateSlideoutMenu( payload );
	}

	handleAddGaugeToMenu( e ) {
		this.props.toggleModal(true);
		this.setState({ modalOpen:true });

	}

	handleAddStationToMenu( e ) {
		this.props.toggleModal(true);
	}

	handleCloseModal(  ) {
		this.props.toggleModal( false );
		this.setState({ modalOpen:false });
	}

	dismissErrorMessage() {
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
	}

	handleDayClick(day, { selected, disabled }){

		if(disabled){
			// Day is disabled do nothing
			return;
		}

		let toRange = this.props.dateTimeSelections.dateTime.range.to;
		let fromRange = this.props.dateTimeSelections.dateTime.range.from;
		
		var rangeObj = {
			from: fromRange,
			to: toRange,
		};	

		const range = DateUtils.addDayToRange(day, rangeObj);
		this.props.handleDateTimeInteraction(range);
	};

	handleResetClick = (e,day) => {
		e.preventDefault();

		var rangeObj = {
			from: null,
			to: null,
		};

		var timeValObj = {};
		this.props.handleDateReset(rangeObj);
		this.props.resetTimeValues(timeValObj);
	};

	searchUpdated( term ) {
		this.props.searchTermUpdated();
		this.searchTerm = term;
	}

	handleSearchListToggleClick(e) {
		e.preventDefault();
		this.props.doShowSearchList();
	}

	getReportTemplateData(e) {

		this.props.togglePreloader(true);
		var url = "";
		var title = "";
		// eslint-disable-next-line
		var type = e.target.id;
		var gaugeId = "";
		switch(e.target.id) {
			case "stream":
			title = "Stream Water Level Report - Step 1 of 1";
			url = getRoute("uniqueStationName", [], { type: "STREAM" });
			type = type.toUpperCase();
			gaugeId = "WL";
			break;

			case "rain":
			title = "Precipitation Report - Step 1 of 1";
			url = getRoute("uniqueStationName", [], { type: "RAIN" });
			type = type.toUpperCase();
			gaugeId = "PRECIP";
			break;

			case "dam":
			title = "Dams Report - Step 1 of 1";
			url = getRoute("uniqueStationName", [], { type: "DAM" });
			type = type.toUpperCase();
			gaugeId = "DAM";
			break;

			case "lower-don":
			title = "Lower Don Report - Step 1 of 1";
			url = getRoute("uniqueStationName", [], { type: "LOWER_DON" });
			type = type.toUpperCase();
			gaugeId = "LOWER_DON";
			break;

			case "temporal":
			title = "Temporal Rainfall Report - Step 1 of 1";
			url = getRoute("uniqueStationName", [], { type: "TEMPORAL" } );
			type = type.toUpperCase();
			gaugeId = "TEMPORAL";
			break;

			default:
			break;
		}

		//console.log(url);

		apiRequest(url) 
		.then(result => {
			var gaugeAry = [];
			let id = 2000;
			let gauges = null;

			for( var i = 0; i < result.length; i++ ) {
				for(var j = 0; j < this.props.dateTimeSelections.masterStationAndGaugeList.length; j++ ) {
					gauges= [];
					if( result[i] ===  this.props.dateTimeSelections.masterStationAndGaugeList[j].stationName ) {
						if( type === "RAIN" ) {
							for( var k = 0; k < this.props.dateTimeSelections.masterStationAndGaugeList[j].gauges.length; k++ ) {
								if( this.props.dateTimeSelections.masterStationAndGaugeList[j].gauges[k].sensorName === "PRECIP" ) {
									gauges.push(this.props.dateTimeSelections.masterStationAndGaugeList[j].gauges[k]);
								}
							}
						}else {
							gauges = [...this.props.dateTimeSelections.masterStationAndGaugeList[j].gauges];
						}

						var obj = {
							stationName: result[i],
							gauges: gauges,
							type: "standard",
							gaugeId: gaugeId,
							title: title,
							id: id
						};

						gaugeAry.push(obj);
						id++;
					}
				}
			}

			this.props.setupStationsAndGauges( gaugeAry );
			this.props.togglePreloader(false);

			if( gaugeId === "LOWER_DON" || gaugeId === "DAM" || gaugeId === "TEMPORAL" ) {
				this.addAllStationsGauges();
			} else {
				this.removeAllGauges();
			}
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 
			/*let errorMsg = "";
			switch(error) {
				default:
				errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
				break;
			}
			this.props.togglePreloader(false);
			var obj = {
				isVisible: true,
				message: errorMsg,
			};

			this.props.showFetchError( obj );*/
		})
	}

	loadRelatedStationGauges( id ) {}

	handleRemoveStationClick( e ) {}

	addGaugeToReport( gauge, stationId ) {
        var obj = {
            gaugeId: gauge,
            stationId: stationId,
		};

		this.props.gaugeAddedToReport( obj );
    }

	removeGaugeSelectionFromMenu( id, gauge, station ) {
		var obj = {
			gauge: gauge,
			id: id,
			station: station,
			reportType: this.props.dateTimeSelections.reportType
		};

		this.props.gaugeRemovedFromReport( obj );
	}

	removeGaugeFromReport( gauge, stationId ) {
        var obj = {
            station: stationId,
			gauge: gauge,
		};
		
		this.props.gaugeRemovedFromReport( obj );
	}
	
	addAllStationsGauges() {
		var gaugeAry = [];
		var gaugeId = "";
		var url = "";
		switch( this.props.dateTimeSelections.reportType ) {
			case "standard":
				switch( this.props.dateTimeSelections.selectedStandardReport ) {
					case "WL":
						url = getRoute("uniqueStationName", [], {type: "STREAM"} );
					break;

					case "PRECIP":
						url = getRoute("uniqueStationName", [], {type: "RAIN"} );
					break;
		
		
					case "DAM":
						url = getRoute("uniqueStationName", [], {type: "DAM"} );
					break;

					case "LOWER_DON":
						url = getRoute("uniqueStationName", [], {type: "LOWER_DON"} );
					break;

					case "TEMPORAL":
						url = getRoute("uniqueStationName", [], {type: "TEMPORAL"} );
					break;

					default:
					break;
				}

				

				this.props.togglePreloader(true);
				apiRequest(url)
				.then(result => {
					
					for( var i = 0; i < result.length; i++ ) {
						for(var j = 0; j < this.props.dateTimeSelections.masterStationAndGaugeList.length; j++ ) {
							if( result[i] ===  this.props.dateTimeSelections.masterStationAndGaugeList[j].stationName ) {
								var gauges = this.props.dateTimeSelections.masterStationAndGaugeList[j].gauges;
								
								for( var k = 0; k < gauges.length; k++ ) {
									gauges[k].selected = true;
								}

								var obj = {
									stationName: result[i],
									gauges: gauges,
									type: "standard",
									gaugeId: gaugeId,
								};
								gaugeAry.push(obj);
								
							}
						}
					}

					//console.log(gaugeAry);

					this.props.allStationsAddedToReport( gaugeAry );
					this.props.togglePreloader(false);
				})
				.catch( (error) => {
					console.log( 'Error', error );
					this.props.togglePreloader(false); 
					/*let errorMsg = "";
					switch(error) {
						default:
						errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
						break;
					}
					this.props.togglePreloader(false);
					var obj = {
						isVisible: true,
						message: errorMsg,
					};
					
					this.props.showFetchError( obj );*/
				})

			break;

			default:
				this.props.togglePreloader(true);
				apiRequest(getRoute('allStationsAndGauges'))
				.then(result => {
					for( var i = 0; i < result.length; i++ ) {
						var gauges = [];
		
						for( var j = 0; j < result[i].gauge.length; j++ ) {
							var gauge = {
								id: result[i].gauge[j].id,
								prettySensorName: result[i].gauge[j].pretty_sensor_name,
								sensorName: result[i].gauge[j].sensor_name,
							} 
		
							gauges.push(gauge);
						}
		
						var obj = {
							stationName: result[i].station_name,
							gauges: gauges,
							isThirdParty: result[i].is_third_party
						};
		
						gaugeAry.push(obj);
					}
					this.props.allStationsAddedToReport( gaugeAry );
					this.props.togglePreloader(false);
				})
				.catch( (error) => {
					console.log( 'Error', error );
					this.props.togglePreloader(false); 
					/*let errorMsg = "";
					switch(error) {
						default:
						errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
						break;
					}
					this.props.togglePreloader(false);
					var obj = {
						isVisible: true,
						message: errorMsg,
					};
					
					this.props.showFetchError( obj );*/
				})
			break; 
		}
	}

	handleGenerateReportClick() {
		var url = generateReport(this.props);

		this.props.storeGeneratedReportUrl(url);

		if( url && url !== undefined ) {
			var from = this.props.dateTimeSelections.dateTime.range.from;
			//var fromStr = from.toString();

			var to = this.props.dateTimeSelections.dateTime.range.to;
			//var toStr = to.toString();

			//create an object to send to local storage to allow user refresh on resilts view
			let reportResults = {
				url: url,
				range: { 
					earliest: this.props.dateTimeSelections.dateTime.range.earliest,
					from: from,
					latest: this.props.dateTimeSelections.dateTime.range.latest,
					to: to,
				},
				stationsAndGauges: this.props.dateTimeSelections.stationsAndAssociatedGauges,
				plotGraphOnSeperateContainer: this.props.dateTimeSelections.plotGraphOnSeperateContainer,
			};

			localStorage.setItem('report-results', JSON.stringify(reportResults));

			this.props.togglePreloader(true);
			//console.log(url);
			apiRequest(url)
			.then(result => {
				this.props.togglePreloader(false);
				//console.log(this.props.dateTimeSelections.plotGraphOnSeperateContainer);
				var reportDetailDataObj = parseReportResultData(result, this.props.dateTimeSelections.plotGraphOnSeperateContainer);

				//console.log( reportDetailDataObj );
				//console.log( result );

				this.props.handleSetReportResults(reportDetailDataObj);
				this.props.history.push("/reports/results");
			})
			.catch( (error) => {
				console.log( 'Error', error );
				this.props.togglePreloader(false); 
				/*let errorMsg = "";
				switch(error) {
					default:
					errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
					break;
				}
				this.props.togglePreloader(false);
				var obj = {
					isVisible: true,
					message: errorMsg,
				};

				this.props.showFetchError( obj );*/
			})
		}
	}

	handleInputChange(e) {
		this.props.plotGraphsOnSeperateContainer( e.target.checked );
	}

	addAllGaugesForSelectedStation(e) {
		this.props.addAllGaugesForSelectedStation( e.currentTarget.dataset.station );
	}

	doRenderSearchInput( filteredStations, stationsOnly ) {

		// these reports do not use the station picker
		if( this.props.dateTimeSelections.selectedStandardReport === "DAM" || 
			this.props.dateTimeSelections.selectedStandardReport === "LOWER_DON" || 
			this.props.dateTimeSelections.selectedStandardReport === "TEMPORAL" ) {
			return null;
		} 
		
		let searchInputValidationMsg = this.props.dateTimeSelections.searchInputValidationErrorMsg;
		
		// build station data
		let stationData = [];
		for (let i = 0; i < filteredStations.length; i++) {


			// cache the station object for easy refrence
			const theStation = filteredStations[i];

			// apply filting
			// when trca filter on only show trca stations
			if( this.state.stationDataFilter === 'trca' && theStation.isThirdParty === true ){
				//console.log('skipping gauge for not trca');
				continue;
			}

			// when third party filter on is on filter out trca stations
			if( this.state.stationDataFilter === 'third-party' && theStation.isThirdParty === false){
				//console.log('skipping gauge for not third party');
				continue;
			}
			
			// build the gauges for this station
			let gauges = [];
			for (let b = 0; b < theStation.gauges.length; b++) {
				gauges.push(  {
					id: theStation.gauges[b].id,
					sensorName: theStation.gauges[b].sensorName,
					selected: theStation.gauges[b].selected,
					searchableString: theStation.searchString,  
				});       
			}

			// setup the station object
			let station = {
				id: theStation.id,
				stationName: theStation.stationName,
				gauges: gauges,
				showThirdPartyFlag: theStation.isThirdParty,
			}


			// add to the collection
			stationData.push(station);
		}

		return(
			<div>
				<SelectGaugeDropdown 
					inputId="report-station-search" 
					giveFocus={ this.state.modalOpen }
					stationsOnly={stationsOnly} 
					stations={stationData}
					addGauges={this.addGauges}
					allowSelectAll={true}
					removeGauges={this.removeGauges}
					stationAddedToReport={ this.props.stationAddedToReport }
				/>
				<span id="search-input-validation" className="error-msg" >{ searchInputValidationMsg }</span>
			</div>
		);
	
	}

	handleTimeToPresentInputChange(e) {
		this.props.setDateTimeToPresent(e.target.checked);
		//this.props.handleDateTimeInteraction(range);
	}

	removeGauges(gauges, stationId){
		this.props.deselectGauges( gauges )
		this.removeGaugeFromReport( gauges, stationId );
	}

	addGauges(gauges, stationId){
		this.props.selectGauges( gauges );
		this.addGaugeToReport( gauges, stationId );
	}
	
	removeAllGauges() {
		this.props.removeAllGaugesFromReport();
	}

	doShowAlarmsToggle(e) {
		this.props.showAlarmsForStandardDonReport(e.target.checked)
	}

	render() {

		const from = this.props.dateTimeSelections.dateTime.range.from;
		const to = this.props.dateTimeSelections.dateTime.range.to;

		let title = null;
		let plotStationsToggle = null;
		let stationsOnly = (this.props.dateTimeSelections.reportType === "custom") ? false : true;

		let searchInput = this.doRenderSearchInput(this.props.dateTimeSelections.stationsAndAssociatedGauges, stationsOnly);

        if(this.props.dateTimeSelections.reportTemplateData.title === "") {
            title = "Create A Custom Report";
        } else {
			title = this.props.dateTimeSelections.reportTemplateData.title;
		}

		if( this.props.dateTimeSelections.selectedStandardReport === "DAM" || this.props.dateTimeSelections.selectedStandardReport === "TEMPORAL" ) {
			plotStationsToggle = null;
		} else {
			plotStationsToggle = 
			<div className="plot-stations-checkbox-toggle">
				<fieldset>
					<label className="plot-stations" htmlFor="plot-stations">
						<input 
							id="plot-stations" 
							type="checkbox" 
							name="plot" 
							value="false" 
							onChange={ this.handleInputChange }
						/>
						Plot stations on separate graphs
					</label>
				</fieldset>
			</div>
		}


		//setup the date restriction
		const today = new Date();
		let yesterday = new Date();
		yesterday.setDate(yesterday.getDate()-1);

		const maxDays = 30;
		let earliestDayWithData = this.props.dateTimeSelections.dateTime.range.earliest;
		let disableAfter = today;
		let disableBefore = null;
		if( this.props.dateTimeSelections.dateTime.range.earliest ) {
			//disableBefore = this.props.dateTimeSelections.dateTime.range.earliest;
		}

		//get the data to disable after 
		if( from ){

			// if from is selected restrict the time x number of days form now or today, whaterver is less
			let maxDay = new Date( from.getTime() );
			maxDay.setDate( maxDay.getDate() + maxDays );

			if( maxDay < today){
				disableAfter = maxDay;
			} 

		}

		if( from && !to ){

			//if from is selected and TO is not restrict the earliest day from the FROM date
			let minDay = new Date( from.getTime() );
			minDay.setDate( minDay.getDate() - maxDays );
			
			if( minDay > new Date(earliestDayWithData) ) {
				disableBefore = minDay;
			} else {
				disableBefore = earliestDayWithData;
			}
			
		}
		else if( to ){
			//if to is slected restrict the earliest day from the to date
			let minDay = new Date( to.getTime() );
			minDay.setDate( minDay.getDate() - maxDays );
			
			if( minDay > new Date(earliestDayWithData) ) {
				disableBefore = minDay;
			} else {
				disableBefore = earliestDayWithData;
			}

			//disableBefore = minDay;
		}

		if( !from && !to ){
			disableBefore = earliestDayWithData;
		}

		let modifiers = {};


		// adds special classes for the date picker from and to
		modifiers.dateFrom = from
		modifiers.dateTo =  to;

		let fromDate = (this.props.dateTimeSelections.dateTime.formattedRange.from) ? this.props.dateTimeSelections.dateTime.formattedRange.from : "(Please select a starting date)";


		let toDate = (this.props.dateTimeSelections.dateTime.formattedRange.to) ? this.props.dateTimeSelections.dateTime.formattedRange.to : "(Please select an end date)";

		let fromTime = this.props.dateTimeSelections.formattedStartTime;
		let toTime = this.props.dateTimeSelections.formattedEndTime;
		let dayPickerValidationMsg = this.props.dateTimeSelections.dateTimeValidationErrorMsg;

		let dateTimeSelectorCss = "";

		if( dayPickerValidationMsg !== "" ) {
			dateTimeSelectorCss = "invalid";	
		} else {
			dateTimeSelectorCss = "";
		}

		let prevMonth = new Date();
		prevMonth.setDate(1);
		prevMonth.setMonth(prevMonth.getMonth() - 1);

		// add the station data filter
		 let filterOptions = [
            {label: "All", value: 'all', id: "filter-all", checked: (this.state.stationDataFilter === 'all' ? true : false )},
            {label: "TRCA Only", value: 'trca', id: "filter-trca", checked: (this.state.stationDataFilter === 'trca' ? true : false )},
            {label: "Third Party Only", value: 'third-party', id: "third-party", checked: (this.state.stationDataFilter === 'third-party' ? true : false )},

        ];

        const filterOptions2 = [];

        for (let i = 0; i < filterOptions.length; i++) {
        	filterOptions2.push(filterOptions[i]);
        	filterOptions2[i].id = filterOptions[i].id + '-2';
        }

        let stationDataFilter = null;
		let stationDataFilter2 = null;

		if( this.props.canAcesssThirdPartyData ){
									
			stationDataFilter = (
				<RadioButtonSeries 
	                title="Select a data type"
	                groupName="display-type"
	                options={filterOptions}
	                handleRadioInputChange={ ( value, name, event, btnID)=> { this.setState({stationDataFilter: value})}}
	                required={false}
	                isValid={true}
	                requiredValidationMessage="Please make a selection"
	            />
	        );

	        stationDataFilter2 = (
				<RadioButtonSeries 
	                title="Select a data type"
	                groupName="filter-type-2"
	                options={filterOptions2}
	                handleRadioInputChange={ ( value, name, event, btnID)=> { this.setState({stationDataFilter: value})}}
	                required={false}
	                isValid={true}
	                requiredValidationMessage="Please make a selection"
	            />
	        );
		}									

		return ( 
			<div className="slideout-panel-wrap reports-container">
				<Helmet>
                    <title>{ pageTitle(title) }</title>
                </Helmet>
				<Preloader showPreloader={ this.props.handleTogglePreloader } message="Generating Report" />
				<ErrorMsg 
					showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
					message={ this.props.fetchErrorMsg.errorMessage } 
					dismissErrorMessage={ this.dismissErrorMessage }	
				/>
				<SlideoutPanel 
					onSlideoutClick={ this.handleSlideoutClick }
					doSlideoutPanel={ this.props.handleToggleSlideoutPanel }
					menuData={ 
						<ReportBuilderMenu 
							updateMenuContentData={ this.props.slideoutMenuUpdateData }
							optionsType={ "rainGaugeReport" } 
							handleUpdateGaugeSelection={ this.removeGaugeSelectionFromMenu }
							selectGaugeToAdd={ this.handleAddGaugeToMenu }
							getReportTemplateData={ this.getReportTemplateData }
							selectedStations={ this.props.dateTimeSelections.selectedStations } 
							reportType={ this.props.dateTimeSelections.reportType }
							reportSelection={ this.props.dateTimeSelections.selectedStandardReport }
							showAlarmsToggle={ this.doShowAlarmsToggle }
							selectedStandardReport={ this.props.dateTimeSelections.selectedStandardReport }
							clearAllStationsFromMenu={ this.props.clearAllStationsFromMenu }
							removeGauges={ this.removeGauges } 
							removeAllGauges={ this.removeAllGauges }
							allStationsAndGauges={ this.props.dateTimeSelections.stationsAndAssociatedGauges }
							canAcccesStandardReports={ this.props.canAcccesStandardReports }
							reportResultsView={ false }
						/>
					}
				/> 

				<div className="slideout-main-content-wrap reports-content-container site-content-wrap" role="main">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12">

								<h1 id="main-heading" className="page-title">{ title }</h1> 

								<span className="reports-instructions"><strong>Select a date range</strong></span>

								<div className="date-time-selector-container">
									<DayPicker
										className={ dateTimeSelectorCss }
										numberOfMonths={2}
										initialMonth={ prevMonth } 
										selectedDays={[from, { from, to }]}
										onDayClick={this.handleDayClick}
										fixedWeeks
										disabledDays={{ after: disableAfter, before: disableBefore } }
										modifiers={ modifiers }
									/>
								</div>
								<span id="day-picker-validation" className="error-msg" >{ dayPickerValidationMsg }</span>

								<div className="time-picker-container">
									<fieldset>  
										<TimePicker  
											id="start-time"
											start="00:00" 
											end="23:59" 
											step={15}
											label="Enter your start time (hh:mm)"
											value={ this.props.dateTimeSelections.startTime }
											timeValueChanged={ this.props.handleTimeValueChange } 
											setTimeIsValid={ this.props.setTimeIsValid }
											isValid={ this.props.dateTimeSelections.startTimeIsValid }
											
										/>
									</fieldset>
									<fieldset>
										<TimePicker 
											id="end-time"
											start="00:00" 
											end="23:59" 
											step={15}
											label="Enter your end time (hh:mm)"
											value={ this.props.dateTimeSelections.endTime }
											timeValueChanged={ this.props.handleTimeValueChange } 
											setTimeIsValid={ this.props.setTimeIsValid }
											isValid={ this.props.dateTimeSelections.endTimeIsValid }
											placeholder={ this.props.dateTimeSelections.formattedEndTime }
										/>
									</fieldset>
									<div className="checkbox-toggle">
										<fieldset>
											<label htmlFor="time-to-present">
												<input 
													id="time-to-present" 
													type="checkbox" 
													name="time-to-present" 
													value="false" 
													onChange={ this.handleTimeToPresentInputChange }
												/>
												To present.
											</label>
										</fieldset>
									</div>
								</div>

								<div className="date-time-feedback">
									You've selected <span>{ fromDate }</span> at <span>{ fromTime }</span> to <span>{ toDate }</span> at <span>{ toTime }</span>
								</div>


								{ stationDataFilter }

								<div className="station-search-container"  role="search">
								
									{ searchInput }
								</div>

								{ plotStationsToggle }

								<div className="report-btn-container">
										<button 
											className="report-btn"
											onClick={ this.handleGenerateReportClick }
										>	
											GENERATE REPORT	
										</button>
									<div className="error-msg">{ this.props.dateTimeSelections.validationErrorMsg }</div>
								</div>
							</div>

						</div>
					</div>
				</div>
		
				
				<Modal 
					isVisible={ this.props.doToggleModal.isVisible }
					//contentType={ this.gaugeType }
					doCloseModal={ this.handleCloseModal }
					//selectedStations={ this.props.dateTimeSelections }
					modalContent={ 
						<div className="station-search-container"  role="search">
							<button 
								className="closeModalBtn" 
								onClick={ this.handleCloseModal }
							>
								<SvgIcon icon="menu-close" />
							</button>
							{ stationDataFilter2 }
							{ searchInput }
						</div>
					}
				/>
			</div>	
		)
	}
}

function mapStateToProps(state) {
	return { 
		handleToggleSlideoutPanel: state.toggleSlideoutPanel,
		doToggleModal: state.toggleModal,
		fetchErrorMsg: state.fetchErrorMsg,
		slideoutMenuUpdateData: state.updateSlideoutMenu,
		dateTimeSelections: state.handleDateTimeInteraction, 
		handleTogglePreloader: state.togglePreloader,
		canAcccesStandardReports: state.auth.canAcccesStandardReports,
		canAcesssThirdPartyData: state.auth.canAcesssThirdPartyData
		
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		toggleSlideoutPanel: toggleSlideoutPanel,
		updateSlideoutMenu: updateSlideoutMenu,
		toggleModal: toggleModal,
		handleDateTimeInteraction: handleDateTimeInteraction,
		handleTimeValueChange: handleTimeValueChange,
		resetTimeValues: resetTimeValues,
		searchFilterInputChange: searchFilterInputChange,
		doShowSearchList: doShowSearchList,
		setReportTemplateData: setReportTemplateData,
		togglePreloader: togglePreloader,
		stationAddedToReport: stationAddedToReport,
		allStationsAddedToReport: allStationsAddedToReport,
		updateSelectedStationGaugesForModal: updateSelectedStationGaugesForModal,
		addStationGaugesToReport: addStationGaugesToReport,
		stationRemovedFromReport: stationRemovedFromReport,
		gaugeAddedToReport: gaugeAddedToReport,
		gaugeRemovedFromReport: gaugeRemovedFromReport,
		handleDateReset: handleDateReset,
		throwValidationError: throwValidationError,
		formValidated: formValidated,
		handleSetReportResults: handleSetReportResults,
		handleShowReportBuilderView: handleShowReportBuilderView,
		plotGraphsOnSeperateContainer: plotGraphsOnSeperateContainer,
		setupStationsAndGauges: setupStationsAndGauges,
		addAllGaugesForSelectedStation: addAllGaugesForSelectedStation,
		storeMasterStationList: storeMasterStationList,
		setTimeIsValid: setTimeIsValid,
		throwDateRangeError: throwDateRangeError,
		throwSearchInputError: throwSearchInputError,
		clearAllStationsFromMenu: clearAllStationsFromMenu,
		setDateTimeToPresent: setDateTimeToPresent,
		deselectGauges: deselectGauges,
		selectGauges: selectGauges,
		setDateRangeForDayPicker: setDateRangeForDayPicker,
		removeAllGaugesFromReport: removeAllGaugesFromReport,
		showFetchError: showFetchError,
		showAlarmsForStandardDonReport: showAlarmsForStandardDonReport,
		resetReportState: resetReportState,
		storeGeneratedReportUrl: storeGeneratedReportUrl,
	 }, dispatch)
}

export default connect( mapStateToProps, matchDispatchToProps )( ReportsContainer ); 