import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRoute, apiRequest } from "../helpers/Api";
import { showLoginModal } from "../actions/Auth";
import PageWrapper from "../components/PageWrapper";

class ActivateAccount extends Component {

    constructor ( props ) {
        super( props );
        this.activateAccount = this.activateAccount.bind(this);
        this.redirectIfLoggedIn = this.redirectIfLoggedIn.bind(this);
        this.state = {
        	isFetching: true,
        	serverMessage: '',
        	accountActivated: false
        }
    }

    componentWillMount(){
    	this.redirectIfLoggedIn();
        window.scrollTo(0, 0);
    }

    redirectIfLoggedIn(){
    	if(this.props.isLoggedIn){
    		this.props.history.replace('/');
    	}
    }

    componentDidMount(){
    	this.activateAccount();
    }

    componentDidUpdate(){
    	this.redirectIfLoggedIn();
    }

    activateAccount(){
    	//console.log(this.props.match.params.token);
    	const activateDetails = { pr_access_code:  this.props.match.params.token };
    	apiRequest( getRoute('activateAccount'), {
		      method: 'POST',
		      body: JSON.stringify(activateDetails)
		    })
    		.then( theResult => {
    			//console.log('activate success', theResult);
    			this.setState({isFetching: false, accountActivated: true})	
    		})
    		.catch( theError => {
    			//console.log('activate error', theError);

    			//bad result
		    	if(theError.badRequest){
		    		// the server returned an error
		    		//console.log('login bad request');
					this.setState({isFetching: false, accountActivated: false, serverMessage: theError.badRequest.non_field_errors})	
		    	}
		    	else{
		    		//network error or something else
		    		//console.log('login network error')
		    		this.setState({isFetching: false, accountActivated: false})	
		    	}
    		})
    }

	render() {

		let message;
		if( this.state.isFetching === true ){
			message = <p>Please wait while we activate your account</p>
		}
		else if( this.state.accountActivated === true ){
			message = <p>Your account has been activated. Would you like to <button className="btn-link" onClick={this.props.showLoginModal}>login</button>?</p>
		}
		else if( this.state.serverMessage !== ''){
			message = <p>{this.state.serverMessage}</p>
		}
		else{
			message = <p>We were unable to activate your account at this time, if you continue to see this message please contact us.</p>
		}

		return (
			<PageWrapper>
                <Helmet>
                    <title>{ pageTitle('Activate Account') }</title>
                </Helmet>
				<h1 id="main-heading" class="page-title">Activate Account</h1>
				{ message }
			</PageWrapper>
		)
   	}
}

function mapStateToProps(state) {
	return { 
        isLoggedIn: state.auth.isLoggedIn
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		showLoginModal : showLoginModal
    }, dispatch)    
}


export default withRouter( connect( mapStateToProps, matchDispatchToProps )( ActivateAccount ) ); 