import React, { Component } from "react";
import SvgIcon from "../SvgIcon";

class SelectGaugeDropdown extends Component {

	/*
		
		inputId = 'somethingUnique' REQUIRED
		allowSelectAll = true REQUIRED -- If all gauges can be selected
		stationsOnly = false REQUIRED - If the dropdown should only show stations
		removeGauges = REQUIRED -- callback that can remove gauges, returns an array of gauge ids to remove
		addGauges = REQUIRED -- callback that can add gauges, returns an array of gauge ids to be added

		stations = { 	REQUIRED
	        id: 123,
	        stationName: 'Some Station',
	        className: 'SomeClass' OPTIONAL -- class name applied to the station wrapper
	        showThirdPartyFlag: true/false OPTIONAL -- used to show a third party flag
	        gauges: [
		        {
			        id: 321,
			        sensorName: 'Some Gauge',
			        selected: true, 
			        searchableString: 'something longer lowercase', -- Must be lowercase to work
			        gaugeDescription: 'Some Text' OPTIONAL -- text shown below the button
			        className: 'SomeClass' OPTIONAL -- class name applied to the gauge button wrapper
			    }
			    ...
			]
	    }


		
		label = 'Something' OPTIONAL
		placeholder = 'Something' OPTIONAL
		selectAllStationsText = 'Something' OPTIONAL -- used for the select all stations text
		removeAllStationsText = 'Somtehing' OPTIONAL -- udsed for the remove all stations text
		selectAllText = 'Soemthing' OPTIONAL
		noGaugesAvailableText = 'Something' OPTIONAL
		addAllText = 'Something' OPTIONAL -- text used for Add all guges
		removeAllText = 'Something' OPTIONAL -- text used to remove all gauges from a station


	*/

	constructor ( props ) {
		super( props );

		// methods
		this.getGaugeOutput = this.getGaugeOutput.bind(this);
		this.getStationOnlyOutput = this.getStationOnlyOutput.bind(this);
		this.getStationWithGaugesOutput = this.getStationWithGaugesOutput.bind(this);
		this.getSelectAll = this.getSelectAll.bind(this);
		this.addGauges = this.addGauges.bind(this);
		this.removeGauges = this.removeGauges.bind(this);
		this.openDropdown = this.openDropdown.bind(this);
		this.openStation = this.openStation.bind(this);
		this.closeStation = this.closeStation.bind(this);
		this.toggleStation = this.toggleStation.bind(this);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.clickDropdownToggleBtn = this.clickDropdownToggleBtn.bind(this);
		this.updateTextFilter = this.updateTextFilter.bind(this);
		this.filterStationsFromTextFilter = this.filterStationsFromTextFilter.bind(this);


		// Default Text
		this.label = (this.props.input ? this.props.input :"Search for a station or parameter:");
		this.placeholder = (this.props.placeholder ? this.props.placeholder :"Station or sensor name");
		this.seeAllText = (this.props.seeAllText ? this.props.seeAllText : 'See all stations');
		this.selectAllStationsText = (this.props.selectAllStationsText ? this.props.selectAllStationsText : 'Add all stations');
		this.removeAllStationsText = (this.props.selectAllStationsText ? this.props.selectAllStationsText : 'Remove all stations');
		this.openStationA11y = 'Open Station';
		this.closeStationA11y = 'Close Station';
		this.addAllText = (this.props.addAllText ? this.props.addAllText : 'Add all');
		this.removeAllText = (this.props.addAllText ? this.props.addAllText : 'Remove all');
		this.noGaugesAvailableText = (this.props.noGaugesAvailableText ? this.props.noGaugesAvailableText : 'No gauges available');
		this.focusDiv = this.focusDiv.bind(this); 

		// this components state
		this.state = {
			dropdownActive: false,
			openStations: [],
			textFilter: '',
		}
	}
	
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps);
		if( nextProps.giveFocus ) {
			
			this.focusDiv(); 
		}
	}

    focusDiv() {
        var _this = this;
        setTimeout(function(){ _this.refs.searchField.focus();},500);  
    }

    removeItem(gauges){

    }


    addGauges( event ){
    	let parsedGauges = event.target.getAttribute('data-gauge').split(',');
		parsedGauges = parsedGauges.map((gauge) => { return parseInt(gauge, 10) }); 
		let stationId = event.target.dataset.stationid;
		
		this.props.addGauges( parsedGauges, stationId );
    }

    removeGauges( event ){
    	let parsedGauges = event.target.getAttribute('data-gauge').split(',');
		parsedGauges = parsedGauges.map((gauge) => { return parseInt(gauge, 10) }); 
		let stationId = event.target.dataset.stationid;
    	this.props.removeGauges( parsedGauges, stationId );
    }

    toggleDropdown(){
    	if( this.state.dropdownActive === true ){
    		this.closeDropdown();
    	}
    	else{
    		this.openDropdown();
   		}
    }

    openDropdown(){
    	this.setState({ dropdownActive: true });
    }

    closeDropdown(){
    	this.setState({ dropdownActive: false, 'textFilter' : '' });
    }

    clickDropdownToggleBtn(){
    	this.toggleDropdown();
    }

    toggleStation( event ){

 		let stationId = parseInt( event.target.getAttribute('data-station'), 10 );
		//alert('toggle station');
		//console.log(stationId);	

    	if( this.state.openStations.indexOf( stationId ) !== -1 ){
    		this.closeStation( stationId );
    	}
    	else{
    		this.openStation( stationId );
    	}
    }

    openStation( stationId ){
    	stationId = parseInt( stationId, 10 );
    	//alert('openStation');
    	let newGauges = [...this.state.openStations];
    	let stationFound = false;
    	for (var i = 0; i < newGauges.length; i++) {
    		if( newGauges[i] === stationId  ){
    			stationFound = true;
    			break;
    		}
    	}

    	if(stationFound === false){
    		//the gauge is not open
    		newGauges.push(stationId);
			this.setState({'openStations': newGauges});
			
			//console.log( stationId );
    	}
    }

    closeStation( stationId ){
    	stationId = parseInt( stationId, 10 );
    	//alert('closeStation');
    	let newGauges = [];
    	let stationFound = false;

    	for (var i = 0; i < this.state.openStations.length; i++) {
    		if( this.state.openStations[i] !== stationId  ){
    			newGauges.push( this.state.openStations[i] );
    		}
    		else{
    			stationFound = true;
    		}
    	}
    	if( stationFound === true ){
    		//only need to update the state if the station was found
			this.setState({'openStations':  newGauges});
    	}

    }

	getStationOnlyOutput(stations){

		const stationOnly = stations.map( (station, stationIndex ) => {

			const allGaugeIds = station.gauges.map( (gauge, gaugeIndex)  => { return gauge.id }); 
			
			//determine the class name
	
			//setup the add all callback\ 
			let allGaugesSelected = true;
			for (var i = 0; i < station.gauges.length; i++) {
				if( station.gauges[i].selected === false || station.gauges[i].selected === undefined ){
					allGaugesSelected = false;
					break;
				}
			}

			//determine the accessibility text and the button icon
			const a11yToggleText = ( allGaugesSelected ? this.removeAllText : this.addAllText );
			const buttonIcon = ( allGaugesSelected ? '-' : '+' );

			let addStationCallback = ( allGaugesSelected ? this.removeGauges : this.addGauges );

			if( station.removeable === false ) {
				addStationCallback = null;
			}

			let stationClassName = 'select-gauge-dropdown-row';
			if( allGaugesSelected ){
				stationClassName += ' station-selected';
			}

			let thirdPartyTag = null;
			if( station.showThirdPartyFlag === true ){
				thirdPartyTag = <span className="third-party-tag">Third Party</span>;
			}
			
			return (
				<li className={stationClassName} key={ `${this.inputId}-station-${station.id}`}  >

					<div className={`select-gauge-dropdown-title ${(station.className ? station.className : '')}`} onClick={ addStationCallback } data-gauge={allGaugeIds.join(',')} >
						{ thirdPartyTag }
						{ station.stationName }
						<button className="select-gauge-dropdown-plus" onClick={ addStationCallback } data-gauge={allGaugeIds.join(',')}><span className="screen-reader-text">{a11yToggleText}</span>{buttonIcon}</button>
					</div>
					
				</li>
			)

		});

		return stationOnly;

	}

	getStationWithGaugesOutput(stations){

		//get the individual stations and gauges
		const stationAndGaugeSelection = stations.map( (station, stationIndex ) => {

			const allGaugeIds = station.gauges.map( (gauge, gaugeIndex)  => { return gauge.id }); 

			const stationOpen = (this.state.openStations.indexOf( station.id ) === -1 ? false : true );

			//determine the class name
			let stationClassName = 'select-gauge-dropdown-row';
			if( stationOpen  ){
				stationClassName += ' station-open';
			}

			//setup the add all callback\
			let allGaugesSelected = true;
			for (var i = 0; i < station.gauges.length; i++) {
				if( station.gauges[i].selected === false || station.gauges[i].selected === undefined ){
					allGaugesSelected = false;
					break;
				}
			}

			//determine the accessibility text and the button icon
			const a11yToggleText = ( stationOpen ? this.closeStationA11y : this.openStationA11y );
			const buttonIcon = ( stationOpen ? '-' : '+' );


			//only add the add all button if all gauges are not selected
			let addAllBtn = null;
			if(allGaugesSelected === false){
				addAllBtn = <div className="gauge-button-wrap"><button className="select-gauge-dropdown-add-all-btn"  data-gauge={ allGaugeIds.join(',') } onClick={this.addGauges} >{ this.addAllText }</button></div>;
			}

			let thirdPartyTag = null;
			if( station.showThirdPartyFlag === true ){
				thirdPartyTag = <span className="third-party-tag">Third Party</span>;
			}




			return (
				<li className={stationClassName} key={ stationIndex} >

					<div className={`select-gauge-dropdown-title ${(station.className ? station.className : '')}`} data-station={station.id} onClick={ this.toggleStation }>
						{ thirdPartyTag }
						{ station.stationName }
						<button className="select-gauge-dropdown-plus" data-station={station.id} onClick={  this.toggleStation }><span className="screen-reader-text">{a11yToggleText}</span>{buttonIcon}</button>
					</div>
	
					<div className="select-gauge-dropdown-content">
						<div className="select-gauge-dropdown-sensor-title">Select sensors:</div>
						{
							station.gauges.map( (gauge, gaugeIndex)  => {
								//sets if the button should remove or add the gauge
								let buttonCallback = ( gauge.selected ?  this.removeGauges :  this.addGauges );
								if( gauge.removeable === false ) {
									buttonCallback = null;
								}

								return (
									<div className={`gauge-button-wrap ${(gauge.className ? gauge.className : '')}`} key={ `${this.inputId}-gauge-${gauge.id}`}>
										<button className={ `select-gauge-dropdown-gauge-btn ${(gauge.selected ? 'gauge-selected' : '' )}`} data-gauge={ gauge.id } data-stationid={ station.id } onClick={ buttonCallback } >
											{ gauge.sensorName }
										</button>
										{ gauge.gaugeDescription ? ( <div className="gauge-button-description">{gauge.gaugeDescription}</div> ) : (null) }
									</div>
								)
							})
						}
						
						{addAllBtn}

					</div>
				
				</li>
			)

		});

		return stationAndGaugeSelection;

	}

	getSelectAll(filteredStations){
		if(this.props.allowSelectAll === true){

			let allGaugeIds = [];
			let allSelected = true;
			for (var i = 0; i < filteredStations.length; i++) {
				const theStation = filteredStations[i];
				for (var b = 0; b < theStation.gauges.length; b++) {
					allGaugeIds.push(  theStation.gauges[b].id );
					// eslint-disable-next-line
					if( allSelected === true && theStation.gauges[b].selected === false || allSelected === true && theStation.gauges[b].selected === undefined ){
						allSelected = false;
					}
				}
			}

			let allSelectedText = this.selectAllStationsText;
			let allSelectedCallback = this.addGauges;
			let buttonIcon = '+';

			if( allSelected ){
				allSelectedText = this.removeAllStationsText;
				allSelectedCallback = this.removeGauges;
				buttonIcon = '-';
			}


			return (
				<li className="select-gauge-dropdown-row">
					<div className="select-gauge-dropdown-title select-all" data-gauge={allGaugeIds.join(',')} onClick={ allSelectedCallback }>
						{ allSelectedText }
						<button className="select-gauge-dropdown-plus" data-gauge={allGaugeIds.join(',')} onClick={ allSelectedCallback }><span className="screen-reader-text">{allSelectedText}</span>{buttonIcon}</button>
					</div>
				</li>
			)
		}
		else{
			return null;
		}
	}

	getGaugeOutput(filteredStations){
		let gaugeOutputType = null;

		if( this.props.stationsOnly === true){
			gaugeOutputType = this.getStationOnlyOutput( filteredStations )
		}
		else{
			gaugeOutputType = this.getStationWithGaugesOutput( filteredStations );
		}

		return (
			<ul className="select-gauge-dropdown-list">
				{ this.getSelectAll(filteredStations) }
				{ gaugeOutputType }
			</ul>
		)
	}

	updateTextFilter(event){

		this.setState({'textFilter':  event.target.value, dropdownActive: true })

	}

	filterStationsFromTextFilter(stations){
		let filteredStations = [];
		if( this.state.textFilter !== ''){
			for (var i = 0; i < this.props.stations.length; i++) {
				const theStation = this.props.stations[i];
				let gauges = [];
				for (var b = 0; b < theStation.gauges.length; b++) {
					if( theStation.gauges[b].searchableString.indexOf( this.state.textFilter.toLowerCase() ) !== -1 ){
						gauges.push( theStation.gauges[b] );
					}
				}

				let filteredGaugesBySensorName = [];

				for( var c = 0; c < gauges.length; c++ ) {
					if( gauges[c].sensorName.toLowerCase().indexOf(this.state.textFilter.toLowerCase()) !== -1 ) {
						filteredGaugesBySensorName.push( gauges[c] );
					}
				}

				if(filteredGaugesBySensorName.length) {
					filteredStations.push({...theStation, ...{gauges:filteredGaugesBySensorName}});
				}else if(gauges.length){
					filteredStations.push({...theStation, ...{gauges:gauges}});
				}
			}
		}
		else{
			filteredStations = this.props.stations;
		}

		return filteredStations;
	}

	render() {
		let filteredStations = this.filterStationsFromTextFilter(this.props.stations);

		let selectedGaugeList = null;
		if( !filteredStations.length ){
			selectedGaugeList = (
				<ul className="select-gauge-dropdown-list">
					<li className="select-gauge-dropdown-row">
						<div className="select-gauge-dropdown-title none-available">
							{ this.noGaugesAvailableText }
						</div>
					</li> 
				</ul>
			)
		}
		else{
			selectedGaugeList = this.getGaugeOutput(filteredStations);
		}

		//console.log(this.props.inputId);

		return (
			<div className={ `select-gauge-dropdown ${(this.state.dropdownActive ? 'dropdown-open' : '')}`}>
				<label htmlFor={this.props.inputId}>{this.label}</label>
				<div className="select-gauge-dropdown-input-warp"> 
					<input id={this.props.inputId} name={this.props.inputId} type="text" className="select-gauge-dropdown-input" placeholder={this.placeholder} value={this.state.textFilter} onChange={this.updateTextFilter} ref="searchField" tabIndex={0} />
					<button className="select-gauge-dropdown-toggle-btn" onClick={this.clickDropdownToggleBtn }>
						{ this.seeAllText }
						<SvgIcon icon="chev-down" />
					</button>
				</div>
				{selectedGaugeList}
			</div>
		)
	}
}

export default SelectGaugeDropdown;