import React, { Component } from "react";
import Card from "../../Card";
import MinimalizedCard from "../../MinimalizedCard";
import { getRoute, apiRequest } from "../../../helpers/Api";
import slugify from "../../../helpers/slugify";
import LoadingSVG from "../../../images/gauge-placeholder.svg";
import LoadingSpinner from "../../../images/loading-spinner.svg";
import { convertWidgetModalTime } from "../../../helpers/DashboardHelpers";
import EditWidgetMenu from "../EditWidgetMenu";

//go through the backlog to quote time left 

class GaugeCardWidget extends Component {

    constructor ( props ) {
        super( props );

        //variables
        this.apiTimeout = null;

        //methods
        this.fetchData = this.fetchData.bind(this);
		this.editWidget = this.editWidget.bind(this);
		this.removeWidget = this.removeWidget.bind(this);
		this.cardRefreshLength = 60000;
		this.checkForSafetyCode = this.checkForSafetyCode.bind(this);
		this.fetchChartData = this.fetchChartData.bind(this);

        //state
        this.state = {
        	isFetching: true,
			apiData: null,
			reportApiData: null,
			apiError: null,
        }
    }

    componentDidMount(){
    	this.fetchData();
    	this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

	}

    componentDidUpdate(prevProps, prevState){
    	//check to see if something was changed that makes us need to update
    	let requireFetch = false;

    	if( this.props.timeFrame !== prevProps.timeFrame){
    		requireFetch = true;
    	}

    	if( this.props.type !== prevProps.type){
    		requireFetch = true;
    	}

    	if( this.props.gaugeId !== prevProps.gaugeId){
    		requireFetch = true;
		}

    	if( requireFetch === true ){
			clearInterval( this.apiTimeout );
			
			if( this.props.reportVisualization ) {
				this.fetchChartData();
			} else {
				this.fetchData();
				this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);
			}
    	}
    }

    componentWillUnmount(){
		//clear the timeout for fetching data
		clearInterval( this.apiTimeout );
		//console.log("component did unmount");
	}

	fetchChartData() {
		//this.setState({isFetching: true });
		
		let dateFrom = new Date(this.props.timeFrame.from);
		let dateTo = new Date(this.props.timeFrame.to);

		//console.log(this.props);

		var getVars = {
			'type_of': 'custom_report',
			// eslint-disable-next-line   
			'timestamp_of_measurement_range_0': parseInt(dateFrom.getTime()/1000), // returns UTC
			// eslint-disable-next-line   
			'timestamp_of_measurement_range_1': parseInt(dateTo.getTime()/1000), // returns UTC
		}

		//console.log(this.props.gaugeId);

		if(this.props.gaugeId.length){
			//console.log("**************");
			getVars.gauge_ids = this.props.gaugeId.join(',');
		} else {
			getVars.gauge_ids = this.props.gaugeId;
		}

		var url = getRoute('reportBuilder', [], getVars);
		//console.log(url);

		apiRequest(url)
		.then(result => {
			this.setState({ reportApiData: result });
		})
		.catch( (error) => {
			console.log( 'Error', error );
			/*let errorMsg = "";
			switch(error) {
				default:
				errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
				break;
			}*/
		})
	}

    fetchData(){
		this.setState({isFetching: true });
		let routeDetails = ["gaugeDetails", this.props.gaugeId.toString()];
		let time = convertWidgetModalTime(this.props.timeFrame);
		routeDetails.push({override_hours_ago:time})
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => { 
			//console.log( result ); 
			this.setState({isFetching: false, apiData: result });
		})
		.catch((error) => {
			console.log('error fetching gauge data');
			//console.log(error.badRequest.detail);
			this.setState({isFetching: false, apiError: error.badRequest.detail });
		});
	}
	
	editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, this.state.apiData.sensor_name, widgetId );
	}

	removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
	}

	checkForSafetyCode(id) {
		//check if station has a dam safety code
		if( this.props.damSafetyCodeData ) {
			let safetyCode = null;
			//console.log(this.props.damSafetyCodeData);
			for( var i = 0; i < this.props.damSafetyCodeData.results.length; i++ ) {
				if( id === this.props.damSafetyCodeData.results[i].station_id ) {

					if( id !== "HY006" ) { //they don't want Bolton Mcfall to show up 
						safetyCode = this.props.damSafetyCodeData.results[i].dam_safety_code;
					}
				}
			}

			return safetyCode;	
		}
		
	}

    render() { 
		let theType = null;
		let precipTotals = null;

    	if( this.props.type === 'time-series' ){
			//theType = 'time-series';
			if( this.props.showAccumulated ) {
				theType = "rain-gauge";
			} else {
				theType = 'time-series';
			}
    	}
    	else if( this.props.type === 'dam-gauge' ){
			if( this.props.gaugeType === "WL" ) {
				
				theType = "time-series";
			} else {
				
				theType = 'dam-gauge';
			}
    	}
    	else if( this.props.type === 'precip-gauge'){
			if( this.props.showAccumulated ) {
				theType = "rain-gauge";

				if( this.props.precipTotals ) {
					for( var i=0; i < this.props.precipTotals.length; i++ ) {
						
						if( this.state.apiData ) {
							if( this.state.apiData.slug === this.props.precipTotals[i].slug ) {
								precipTotals = this.props.precipTotals[i];
							}
						}
					}
				}

			} else {
				theType = 'time-series';
			}
    	}
    	else{
    		throw new Error(`Unknown type prop passed to GaugeCardwidget: ${this.props.type}`);
    	}


        if( this.props.size !== 'small' && this.props.size !== 'large' && this.props.size !== 'medium'){
            throw new Error(`Unknown prop size was passed to GaugeCardWidget: ${ this.props.size}. Supported sizes are 'small' and 'large'`);
		}
		
    	let className = `dashboard-grid-item dashboard-grid-item-gauge-card ${this.props.size}`;
        let cardContent = null;
    	if( this.state.apiData === null )
    	{
			if(this.state.apiError) {
				cardContent = (
					<div className="card gauge">
						<h2>{this.state.apiError}</h2>
						<img src={ LoadingSVG } className="guage-loading-placeholder" alt="" />
					</div>
				);
			} else {
				cardContent = (
					<div className="card gauge">
						<h2 className="gauge-name">Loading&hellip;</h2>
						<img src={ LoadingSVG } className="guage-loading-placeholder" alt="" />
					</div>
				);
			}
    	}
    	else{ 
			//console.log(theType);
			//console.log( precipTotals );

			if( this.props.reportVisualization ) {
				if(this.state.reportApiData) {
					cardContent = 
					<MinimalizedCard  
						id={ slugify(this.state.reportApiData.data[0].station_id + "-" + this.state.reportApiData.data[0].station_name) }
						cardType={theType}
						widgetType={this.props.widgetType}
						data={ this.state.reportApiData.data[0] } 
						doUpdate={ false }
						hasError={this.state.apiError}
						safetyCode={this.checkForSafetyCode(this.state.reportApiData.data[0].station_id)}
					/>
				}
				
			} else {
				cardContent = 
    			<Card  
					id={ slugify(this.state.apiData.station_id + "-" + this.state.apiData.station_name) }
					cardType={theType}
					data={ this.state.apiData } 
					doUpdate={ true }
					safetyCode={this.checkForSafetyCode(this.state.apiData.station_id)}
					precipTotals={ precipTotals }
					hasError={this.state.apiError}
				/>
			}
    	}

    	let loadingWheel = null;
    	if( this.state.isFetching ){
    		loadingWheel = <div className="dashboard-grid-item-loading-wheel"><img src={ LoadingSpinner }  alt="Updating Card" /></div>
		}
		
		let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
			savedReport={ this.props.reportVisualization }
		/>
		: null;

    	return (
    		<div className={ className }>
				{ cardContent }
				{ loadingWheel }
				{ editWidgetMenu }
				
    		</div>
    	)
    }
}

export default GaugeCardWidget;

