import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Route
} from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Switch } from 'react-router';
import {Helmet} from "react-helmet";

import HeaderContainer from './containers/HeaderContainer';
import GaugeMapContainer from './containers/GaugeMapContainer';
import StationDetailContainer from './containers/StationDetailContainer';
import ReportsContainer from './containers/ReportsContainer';
import ReportResultsContainer from './containers/ReportResultsContainer';
import DataExplorerContainer from './containers/DataExplorerContainer';
import DocumentsContainer from './containers/DocumentsContainer';
import FooterContainer from './containers/FooterContainer';
import FourOhFour from './containers/FourOhFour';
import DashboardContainer from './containers/DashboardContainer';
import DamOperationsContainer from './containers/DamOperationsContainer';
import BackToTop from './components/BackToTop';
import LoginModal from "./components/auth/LoginModal";
import TwitterUpdateBar from './components/TwitterUpdateBar';
import FloodStatusBar from './components/FloodStatusBar';
import ActivateAccount from './containers/ActivateAccount';
import RegisterAccount from './containers/RegisterAccount';
import ResetPassword from './containers/ResetPassword';
import BatteryDataContainer from './containers/BatteryDataContainer';
import FaqContainer from "./containers/FaqContainer";
import SiteInformationContainer from './containers/SiteInformationContainer';
import EditProfileContainer from './containers/EditProfileContainer';
import CameraViewContainer from './containers/CameraViewContainer';
import DamSafetyCodeContainer from './containers/DamSafetyCodeContainer';
import DamOperationLogContainer from './containers/DamOperationLogContainer';
import DamOperationAddLogContainer from './containers/DamOperationAddLogContainer';


import './App.css';

class App extends Component {  
	constructor(props) {
		super(props)

		this.activeFloodWarningInEffect = this.activeFloodWarningInEffect.bind(this);
	}

	activeFloodWarningInEffect(status) {
		this.props.activeFloodWarningInEffect(status);
	}

	render() {

		let homeRoute = null;
		if( this.props.canAccessCustomDashboards ){
			homeRoute = <Route exact path="/" component={DashboardContainer} />
		}
		else{
			homeRoute = <Route exact path="/" component={GaugeMapContainer} /> 
		}

		let slideoutWrapClass = ( this.props.navOpen ? 'slideout-wrap slideout-open' : 'slideout-wrap' )

		return (
			<div className="app-container">
				<Helmet>
	                <title>TRCA - Real Time Gauging</title>
	            </Helmet>
				<Router>
					<div>
						<a className="skip-to-main" href="#main-heading">Skip to content</a> 
						<FloodStatusBar /*activeFloodWarningInEffect={ this.activeFloodWarningInEffect }*/ />
						<div className={slideoutWrapClass}>
							<HeaderContainer activeFloodWarning={ this.props.floodWarningInEffect }/>
							<TwitterUpdateBar />
							<Switch>
								{ homeRoute }						
								<Route exact path="/station-map" component={GaugeMapContainer} />
								<Route path="/gauge/:station" component={StationDetailContainer} /> 
								<Route exact path="/reports" component={ReportsContainer} />
								<Route exact path="/reports/results" component={ReportResultsContainer} />
								<Route exact path="/data-explorer" component={DataExplorerContainer} />
								<Route exact path="/documents" component={DocumentsContainer} />
								<Route exact path="/dashboard" component={DashboardContainer} />
								<Route exact path="/dam-ops" component={DamOperationsContainer} />
								<Route exact path="/dam-ops/dam-safety-logs/:stationSlug" component={DamSafetyCodeContainer} />
								<Route exact path="/dam-ops/dam-operation-logs/:stationSlug" component={DamOperationLogContainer} />
								<Route exact path="/dam-ops/add-dam-operation/:stationSlug" component={DamOperationAddLogContainer} />
								<Route exact path="/account/activate/:token" component={ActivateAccount} />
								<Route exact path="/account/reset-password/:token" component={ResetPassword} />
								<Route exact path="/account/register" component={RegisterAccount} />
								<Route exact path="/battery-data" component={BatteryDataContainer} />
								<Route exact path="/faq" component={FaqContainer} />
								<Route exact path="/site-info/:info" component={SiteInformationContainer} />
								<Route exact path="/edit-profile" component={EditProfileContainer} />
								<Route exact path="/camera-views" component={CameraViewContainer} />
								<Route exact path="*" component={FourOhFour} />
							</Switch>
							<FooterContainer />
						</div> 
						<LoginModal />
						<BackToTop />
					</div>	
				</Router>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		isLoggedIn: state.auth.isLoggedIn,
        canAccessCustomDashboards: state.auth.canAccessCustomDashboards,
		navOpen: state.mainNavigation.visible,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({  }, dispatch)    
}

export default connect(mapStateToProps, matchDispatchToProps)(App);

  