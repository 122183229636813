import React, { Component } from "react";
import PageWrapper from "../components/PageWrapper";
import Register from "../components/auth/Register";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";


class RegisterAccount extends Component {
    constructor ( props ) {
        super( props );

        this.onSubmitError = this.onSubmitError.bind(this);

        this.state = {
        	scrollToError: false
        }
    }


    componentWillMount(){
        window.scrollTo(0, 0); 
    }


    componentDidUpdate(){

        if( this.state.scrollToError === true ){
            const errors = document.getElementsByClassName('form-field-validation-error');
            if( errors.length ){
                errors[0].scrollIntoView();
            }
            this.setState({'scrollToError': false});
        }
    }

    onSubmitError(){
    	this.setState({'scrollToError': true});
    }
	render() {

		return (
			<PageWrapper>
                <Helmet>
                    <title>{ pageTitle('Register Account') }</title>
                </Helmet>
				<h1 id="main-heading" className="page-title">Register Account</h1>
				<Register 
					onSubmitError={this.onSubmitError}
					/>
			</PageWrapper>
		)
   	}
}
export default RegisterAccount;