import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";

import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import ErrorMsg from "../components/ErrorMsg";
import { connect } from "react-redux"; 
import { RequireCapability } from "./RequireCapability";
import { doAuthCheck } from "../actions/Auth";
import { yyyymmdd } from "../helpers/gaugeHelpers";
import PageWrapper from "../components/PageWrapper";
import { 
    togglePreloader,
    setDocumentLibrary,
    showFetchError,
} from "../actions/index";

class DocumentsContainer extends Component {
    constructor ( props ) {
        super( props ); 

        this.parseDocumentLibraryResults = this.parseDocumentLibraryResults.bind(this);
        this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
    }

    componentWillMount() {
        this.props.togglePreloader(true);
        apiRequest( getRoute('documents')) 
		.then(result => {
            this.props.togglePreloader(false);
            this.props.setDocumentLibrary( result );
        })
        .catch( (error) => {
            if( error.status === 403 ){
                this.props.doAuthCheck();
                return error;
            }

            //console.log( 'Error', error );
			this.props.togglePreloader(false); 


            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
    }

    parseDocumentLibraryResults( data ) {
        let categories = data.map((category, index) => {
            let documents = category.documents.map(( doc, index ) => {
                return(
                    <div className="document" key={ "category-"+index }>
                        <form method="get" action={ doc.uploaded_file } target="_blank">
                            <button className="btn-link h3 document-title" type="submit">{ doc.name }</button>
                        </form>
                        <p>{ doc.description }</p>
                        <span>Last Updated { yyyymmdd( new Date(doc.timestamp_of_last_modified) ) }</span>
                    </div>
                )
            });
           return ( 
                <div className="document-category" key={ "document-"+ index } >
                    <h2 className="document-category-title">{ category.name }</h2>
                    <div className="documents-container">
                        { documents }
                    </div>
                </div>
           ) 
        });


        return categories;
    }

    dismissErrorMessage() {
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
	}

	render() {
        let documentLibrary = null;
        if( this.props.documents.documentData ) {
            documentLibrary = this.parseDocumentLibraryResults( this.props.documents.documentData.results );
        } 

		return (
			<PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle('Document Library') }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching Documents" />
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }	
                />

                <h1 id="main-heading" className="page-title">Document Library</h1>
                { documentLibrary }
 
            </PageWrapper>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        documents: state.documentLibrary,
        fetchErrorMsg: state.fetchErrorMsg,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        setDocumentLibrary: setDocumentLibrary,
        showFetchError: showFetchError,
        doAuthCheck: doAuthCheck,
	}, dispatch)	
}

export default RequireCapability( withRouter( connect( mapStateToProps, matchDispatchToProps )( DocumentsContainer ) ), 'canAccessDocumentLibrary' ); 