import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle"; 

import { 
	togglePreloader,
	updateStationDetailData,
	updateBatteryLevelChartData,
	updateSpecificNeighbourhoodGaugeData,
	resetStationDetailData,
	gaugeNotFound,
	toggleModal,
	showFetchError,
} from "../actions/index";
 
import { 
	yyyymmdd, 
 } from "../helpers/gaugeHelpers";

import Preloader from "../components/Preloader";
import FourOhFour from './FourOhFour';
import StationPrimary from "../components/station-detailed/StationPrimary";
import StationSecondary from "../components/station-detailed/StationSecondary";
import StationBattery from "../components/station-detailed/StationBattery";
import RainAccumulatedTotals from '../components/station-detailed/RainAccumulatedTotals';
import ErrorMsg from "../components/ErrorMsg";
import { getRoute, apiRequest } from "../helpers/Api";
import Modal from "../components/Modal";
import SvgIcon from "../components/SvgIcon";
import DamCalculator from "../components/DamCalculator";
//import $ from 'jquery';
import FeedbackWidget from "../components/FeedbackWidget";

import LoadingSVG from "../images/gauge-placeholder.svg";
//import NeighbouringGauges from "../components/station-detailed/NeighbouringGauges";
	
class StationDetailContainer extends Component {
	constructor({props}) {
		super(props);

		this.state = {
			precipitationTotals: null,
		};
		
		this.apiTimeout = null;
		this.primaryhoursSelection = null;
		this.batteryHoursSelection = null;
		this.neighbouringGaugesHours = [];

		this.getDataFromWebservice = this.getDataFromWebservice.bind(this);
		this.getBatteryGauge = this.getBatteryGauge.bind(this);
		this.checkForNeighbouringGauges = this.checkForNeighbouringGauges.bind(this);
		this.getNeighbouringGauges = this.getNeighbouringGauges.bind(this);
		this.handleBatteryLevelChart = this.handleBatteryLevelChart.bind(this);
		this.updatePrimaryGauge = this.updatePrimaryGauge.bind(this);
		this.handlePrimarySpecificTimeSeriesRequest = this.handlePrimarySpecificTimeSeriesRequest.bind(this);
		this.handleSecondarySpecificTimeSeriesRequest = this.handleSecondarySpecificTimeSeriesRequest.bind(this);
		this.handleBatterySpecificTimeSeriesRequest = this.handleBatterySpecificTimeSeriesRequest.bind(this);
		this.handleSecondaryGaugeSwitch = this.handleSecondaryGaugeSwitch.bind(this);
		this.updateBatteryGauge = this.updateBatteryGauge.bind(this);
		this.getPreviousMonthTotals = this.getPreviousMonthTotals.bind(this);
		this.getAccumulatedTotals = this.getAccumulatedTotals.bind(this);
		this.showDamCalculator = this.showDamCalculator.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
		this.getPrecipTotals = this.getPrecipTotals.bind(this);
	}

	componentWillMount(){
		this.props.resetStationDetailData();
	}

	componentDidMount(){  
		window.scrollTo(0, 0);
		this.apiTimeout = setInterval(this.getDataFromWebservice, 60000);
		this.getDataFromWebservice(); 		
	}

	componentWillUnmount(){
		//clear the timeout for fetching data
		clearTimeout( this.apiTimeout );
	}

    getDataFromWebservice() {
		this.props.togglePreloader(true); 
		let routeDetails = ["gaugeDetails", this.props.match.params.station];
		
		if( this.primaryhoursSelection ) {
			routeDetails.push({override_hours_ago: this.primaryhoursSelection} );
		}

		//apiRequest( getRoute("gaugeDetails", this.props.match.params.station) );
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {   
			//console.log( result );
			if( result.detail && result.detail === "Not found." ){
				//The gauge was not found
				this.props.gaugeNotFound();
			}
			else{
				this.props.togglePreloader(false);
				this.props.updateStationDetailData(result);
				this.getNeighbouringGauges();
				this.getBatteryGauge(result);
				this.getPrecipTotals();
			}
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 

            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
			}
			
			console.log(error);
            //this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj ); */
        })
	}

	checkForNeighbouringGauges() {
		//check if neighbouring gauges exist
		if( this.props.currentStationData.primaryStation.data.neighbouring_gauges.length ) {
			//found some, load them in order

			var neighbourGauges = [];
			for(var i=0; i < this.props.currentStationData.primaryStation.data.neighbouring_gauges.length; i++ ) {
				var neighbourObj = {};
				neighbourObj.nearbyName = this.props.currentStationData.primaryStation.data.neighbouring_gauges[i].nearby_name;
				neighbourObj.url = this.props.currentStationData.primaryStation.data.neighbouring_gauges[i].detail_url; 
				neighbourGauges.push(neighbourObj);
			}
			this.currentGauge = 0;
			this.getNeighbouringGauges( neighbourGauges );
		} 
	}

	getNeighbouringGauges() {
		//console.log( this.neighbouringGaugesHours );
		//console.log( this.props.currentStationData.neighbouringStations );

		for (var i = 0; i < this.props.currentStationData.neighbouringStations.length; i++) {
			
			const gaugeIndex = i;
			
			apiRequest( this.props.currentStationData.neighbouringStations[i].detailUrl )
			.then(result => {    
				//console.log(result);
				if( this.neighbouringGaugesHours.length ) {
					for( var j = 0; j < this.neighbouringGaugesHours.length; j++ ) {
						if( this.neighbouringGaugesHours[j].hours ) {
							this.updateSecondaryGauge(this.neighbouringGaugesHours[j].gaugeIndex, this.neighbouringGaugesHours[j].hours)
						} else {
							this.props.updateSpecificNeighbourhoodGaugeData(gaugeIndex, result);			
						}
					}
				} else {
					this.props.updateSpecificNeighbourhoodGaugeData(gaugeIndex, result);

					if(this.props.currentStationData.neighbouringStations.length > this.neighbouringGaugesHours.length ) {
						var obj = {
							gaugeIndex: gaugeIndex,
							hours: null,
							name: this.props.currentStationData.neighbouringStations.nearbyName
						};

						this.neighbouringGaugesHours.push(obj);
					}
				}
				
				
			})
			.catch( (error) => {
				console.log( 'Error', error );
				this.props.togglePreloader(false); 
				/*let errorMsg = "";
				switch(error) {
					default:
					errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
					break;
				}
				this.props.togglePreloader(false);
				var obj = {
					isVisible: true,
					message: errorMsg,
				};
	
				this.props.showFetchError( obj );*/
			})
		}

		
	}

	updatePrimaryGauge(hours=null){
		
		let routeDetails = ["gaugeDetails", this.props.currentStationData.primaryStation.data.slug];
		
		if( hours !== null){
			this.primaryhoursSelection = hours;
			routeDetails.push({override_hours_ago:hours});
		}

		//console.log(routeDetails);

		//this.props.togglePreloader(true);
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {      

			//console.log(result);
			this.props.togglePreloader(false);
			this.props.updateStationDetailData(result);
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 

            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
	}

	updateSecondaryGauge(gaugeIndex, hours=null){
		//console.log('updateSecondaryGauge', gaugeIndex);
		//this.props.togglePreloader(true);
		let routeDetails = ["gaugeDetails", this.props.currentStationData.neighbouringStations[gaugeIndex].data.slug];
		if( hours !== null){
			for( var i=0; i < this.neighbouringGaugesHours.length; i++ ) {
				if( gaugeIndex === this.neighbouringGaugesHours[i].gaugeIndex ) {
					this.neighbouringGaugesHours[i].hours = hours;
				}
			}

			routeDetails.push({override_hours_ago:hours})
		}
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {    
			this.props.togglePreloader(false);
			this.props.updateSpecificNeighbourhoodGaugeData(gaugeIndex, result);
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
	}

	updateBatteryGauge(hours){
		let routeDetails = ["gaugeDetails", this.props.currentStationData.batteryLevelStation.data.slug];
		if( hours !== null){
			this.batteryHoursSelection = hours;
			routeDetails.push({override_hours_ago:hours})
		}
		//this.props.togglePreloader(true);
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {      
			this.props.togglePreloader(false);
			this.props.updateBatteryLevelChartData(result);
		})
		.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 

            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
	}

	handlePrimarySpecificTimeSeriesRequest(hours){
		this.updatePrimaryGauge(hours);
	}

	handleSecondarySpecificTimeSeriesRequest(hours, gaugeIndex){
		this.updateSecondaryGauge(gaugeIndex, hours);
	}

	handleBatterySpecificTimeSeriesRequest(hours){
		this.updateBatteryGauge(hours)
	}


	handleBatteryLevelChart(result) {
		this.props.updateBatteryLevelChartData(result);
	}

	handleSecondaryGaugeSwitch(requestedGauge, gaugeIndex, hours){

		let routeDetails = ["gaugeDetails", requestedGauge, {override_hours_ago:hours} ];

		//this.props.togglePreloader(true);
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {       
			this.props.togglePreloader(false);
			this.props.updateSpecificNeighbourhoodGaugeData(gaugeIndex, result);
		})
		/*.catch( (error) => {
			console.log( 'Error', error );
			this.props.togglePreloader(false); 
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        //})
 
	}

	getBatteryGauge(apiData){

		//don't update battery if not available
		if( apiData.battery_gauge_url === null ){
			return;
		}

		if( this.batteryHoursSelection ) {
			this.updateBatteryGauge( this.batteryHoursSelection );
		} else {
			apiRequest(apiData.battery_gauge_url)
			.then(result => {
				this.handleBatteryLevelChart( result );
			})
			.catch( (error) => {
				console.log( 'Error', error );
				this.props.togglePreloader(false); 
				/*let errorMsg = "";
				switch(error) {
					default:
					errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
					break;
				}
				this.props.togglePreloader(false);
				var obj = {
					isVisible: true,
					message: errorMsg,
				};

				this.props.showFetchError( obj );*/
			})
		}
	}

	getPreviousMonthTotals(prevMonths){

		if( this.props.currentStationData.previousMonthlyTotals === null){
			return null;
		}

		return (
			<div className="previous-monthly-totals-container">
				<h2 className="">Previous Monthly Totals</h2>
				<div className="responsive-table-wrap">

					<table className="previous-monthly-totals-table table-lined">
						<thead>
							<tr>
								<th>Station</th>
								{
									this.props.currentStationData.previousMonthlyTotals.totals.map((object, i) => {
										return <th key={object.year}>{object.year}</th>;
									})
								}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{ this.props.currentStationData.previousMonthlyTotals.stationName }

								</td>
								{ 
									this.props.currentStationData.previousMonthlyTotals.totals.map((object, i) => {
										return <td key={object.year}>{object.value}</td>;
									})
								}
							</tr>
						</tbody>
					
					</table>
				</div>
			</div>
		)

	}

	getAccumulatedTotals(){

		if( this.props.currentStationData.primaryStation.data.accumulated_totals.length ){
			const accumulatedTotals = [{
				stationName: this.props.currentStationData.primaryStation.data.station_name,
				lastMeasurement: this.props.currentStationData.primaryStation.data.last_updated,
				accumulatedTotals: this.props.currentStationData.primaryStation.data.accumulated_totals
			}]
			return (
				<div className="accumulated-totals-container">
					<h2 className="">Accumulated Totals (mm)</h2>
					<RainAccumulatedTotals rainGaugeTotalCollection={ accumulatedTotals  } />
				</div>
			)
			
		}
		
	}

	showDamCalculator() {
		this.props.toggleModal(true);	
	}

	handleCloseModal(  ) {
		this.props.toggleModal( false );
	}

	dismissErrorMessage() {
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
	}

	getPrecipTotals() {
		apiRequest( getRoute("precipTotals"))
		.then(result => {
			//console.log(result);
			this.setState({ precipitationTotals: result.results });

		})
		.catch( (error) => {
			console.log( 'Error', error );
		})
    }

	render() { 

		//show 404 page if page not found
		if( this.props.currentStationData.is404 ){
			return <FourOhFour />;
		}

		if( !this.props.currentStationData.primaryStation.data ){
			return (
				<div className="site-content-wrap station-details clear" role="main">
					<div className="station-detail-primary gauge" role="region" aria-labelledby="primary-gauge-region">
						<h2 className="screen-reader-text" id="primary-gauge-region">Selected gauge details</h2>
						<div className="container-fluid">
							<div className="row">
								<div className="station-details-info col-sm-12 col-md-6 col-lg-5">
									<h2 className="gauge-name">Loading&hellip;</h2>
									<img src={ LoadingSVG } className="guage-loading-placeholder" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} 

		//console.log(this.props.currentStationData);
		//console.log(precipTotals);
		let secondaryPrecipTotals = [];

		if( this.props.currentStationData.neighbouringStations && this.state.precipitationTotals ) {
			for( var j=0; j<this.props.currentStationData.neighbouringStations.length; j++ ) {
				if( this.props.currentStationData.neighbouringStations[j].data ) {
					//console.log( this.props.currentStationData.neighbouringStations[j].data );
					//if( this.props.currentStationData.neighbouringStations[j].data.sensor_name === "PRECIP" ) {
						for( var k=0; k < this.state.precipitationTotals.length; k++  ) {
							//console.log( this.props.currentStationData.neighbouringStations[j].data );
							//console.log( this.state.precipitationTotals[k] );
							if( this.props.currentStationData.neighbouringStations[j].data.station_name === this.state.precipitationTotals[k].station_name ) {
								//console.log("***SUP***");
								//console.log( this.props.currentStationData.neighbouringStations[j].data.station_name );
								//console.log( this.state.precipitationTotals[k].station_name );
								secondaryPrecipTotals.push(this.state.precipitationTotals[k]);
							}
						}
					//}
				}
			}
		}

		//console.log(secondaryPrecipTotals);

		const neighbours = this.props.currentStationData.neighbouringStations.map(( gauge, index ) => {
			//console.log(gauge);
			let precipTotal = null;
			for(var i=0; i < secondaryPrecipTotals.length; i++) {
				if(secondaryPrecipTotals[i] && gauge.data) {
					if(secondaryPrecipTotals[i].slug === gauge.data.slug) {
						precipTotal = secondaryPrecipTotals[i];
						//console.log(precipTotal);
					}
				}
			}
			
			return(
				<StationSecondary  
					key={ index }
					gaugeIndex={ index }
					stationData={ gauge }
					handleSpecificTimeSeriesRequest={ this.handleSecondarySpecificTimeSeriesRequest }
					handleGaugeSwitch={ this.handleSecondaryGaugeSwitch }
					isLoggedIn={ this.props.isLoggedIn }
					precipTotals={ precipTotal }
				/>
			)
		});

		let installation = null;
		if( this.props.currentStationData.primaryStation.data.installation_date ){
			installation = <div className="primary-guage-installation">Installation Date: { yyyymmdd( new Date( this.props.currentStationData.primaryStation.data.installation_date ) ) }</div>
		}

		let lastService = null;
		if( this.props.currentStationData.primaryStation.data.last_service_date ){
			lastService = <div className="primary-guage-installation">Last Serviced: { yyyymmdd( new Date( this.props.currentStationData.primaryStation.data.last_service_date ) ) }</div>
		}
		
		let doShowDamCalculator = false;
		if( this.props.canAccessDamCalculatorTools && this.props.currentStationData.primaryStation.data ) {
			//G Ross Lord Dam id = 52. Only show dam caculator for G Ross
			if( this.props.currentStationData.primaryStation.data.id === 52 ) {
				doShowDamCalculator = true;
			}
		}
		//console.log(this.props.location.pathname);
		let feedbackWidget = this.props.isLoggedIn ? <FeedbackWidget location={ this.props.location.pathname }/> : null; 

		//console.log(this.props.currentStationData.primaryStation.data.slug);
		//console.log(this.state.precipitationTotals);

		let precipTotals = null;

		if( this.state.precipitationTotals && this.props.currentStationData.primaryStation.data.sensor_name === "PRECIP" ) {
			for( var i=0; i < this.state.precipitationTotals.length; i++ ) {
				if( this.props.currentStationData.primaryStation.data.slug === this.state.precipitationTotals[i].slug ) {
					//console.log("***MATCH***");
					precipTotals = this.state.precipitationTotals[i];
				}
			}
		}

		return(
			<div className="site-content-wrap station-details clear" role="main">

				<Helmet>
                    <title>{ pageTitle(this.props.currentStationData.primaryStation.data.station_name) }</title>
                </Helmet>

				<Preloader showPreloader={ this.props.handleTogglePreloader } />
				<ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }	
                />

				<StationPrimary  
					stationData={ this.props.currentStationData.primaryStation } 
					handleSpecificTimeSeriesRequest={ this.handlePrimarySpecificTimeSeriesRequest }  
					showDamCalculator={ this.showDamCalculator }
					doShowDamCalculator={ doShowDamCalculator }
					isLoggedIn={ this.props.isLoggedIn }
					precipTotals={ precipTotals }
				/>


				<div className="container-fluid" role="region" aria-labelledby="secondary-gauge-region">
					<h2 className="screen-reader-text" id="secondary-gauge-region">Neighbouring gauges details</h2>

					{ this.getAccumulatedTotals() }

					{ this.getPreviousMonthTotals( this.props.currentStationData.previousMonthlyTotals ) }

					<div className="neighbouring-gauges-container">
						{ neighbours }
					</div>

					<div className="battery-level-chart-container row">
						<div className="col-xs-12 col-sm-6">
							<StationBattery 
								stationData={ this.props.currentStationData.batteryLevelStation } 
								handleSpecificTimeSeriesRequest={ this.handleBatterySpecificTimeSeriesRequest }
								isLoggedIn={ this.props.isLoggedIn }
							/>
						</div>
					</div>

					{ installation }
					{ lastService }

					{ feedbackWidget }
				</div>
				{ doShowDamCalculator ? (
					<Modal 
						isVisible={ this.props.doToggleModal.isVisible }
						isFor="damCalculator"
						doCloseModal={ this.handleCloseModal }
						//selectedStations={ this.props.dateTimeSelections }
						modalContent={ 
							<div className="dam-calculator-container">
								<button 
									className="closeModalBtn" 
									onClick={ this.handleCloseModal }
								>
									<SvgIcon icon="menu-close" />
								</button>
								<h2 className="dam-calculator-title">Dam Operations Calculator</h2>
								<DamCalculator />
							</div>
						}
					/>
					): (null)
				}
			</div>
		)
	}

}

function mapStateToProps(state) {
	return { 
		handleTogglePreloader: state.togglePreloader,
		currentStationData: state.updateStationDetailData,
		doToggleModal: state.toggleModal,
		isLoggedIn: state.auth.isLoggedIn,
		canAccessDamCalculatorTools: state.auth.canAccessDamCalculatorTools,
		fetchErrorMsg: state.fetchErrorMsg,
		//isLoggedIn: state.auth.isLoggedIn,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		togglePreloader: togglePreloader,
		updateStationDetailData: updateStationDetailData,
		updateBatteryLevelChartData: updateBatteryLevelChartData,
		updateSpecificNeighbourhoodGaugeData: updateSpecificNeighbourhoodGaugeData,
		resetStationDetailData: resetStationDetailData,
		gaugeNotFound: gaugeNotFound,
		toggleModal: toggleModal,
		showFetchError: showFetchError,
	}, dispatch)	
}

export default connect( mapStateToProps, matchDispatchToProps )( StationDetailContainer ); 