import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { hideLoginModal, showLoginModal, login, logout } from "../../actions/Auth";
import { setAvailableDashboards } from "../../actions/Dashboard";
import { apiRequest, getRoute } from "../../helpers/Api";
import parseLoginApiData from "../../helpers/Auth";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import SvgIcon from "../SvgIcon";
import Modal from "../Modal";

class LoginModal extends Component {

	constructor(props) {
		super(props);
		this.closeLoginModal = this.closeLoginModal.bind(this);
		this.loginNavClick = this.loginNavClick.bind(this);

		this.state ={
			currentForm: 'login',
			loginMessage: '',
		}
	}

	closeLoginModal(){
		this.props.hideLoginModal();
	}

	doAuthCheck(){
		apiRequest( getRoute('userInfo', [this.props.userId]) )
			.then( result => {
				let loginDetails = parseLoginApiData( result );
				this.props.login(loginDetails);
				this.props.setAvailableDashboards( result.dashboards );
			})
			.catch( error => {
				this.props.logout();
				this.setState({
					loginMessage: 'We were unable to verify your login session and have been logged out. Please enter your login credentials.',
					currentForm: 'login',
				});
				this.props.showLoginModal()
				//console.log('auth check error', error);
			});

	}

	componentWillMount(){

		//always check the auth on the first page load
		if( this.props.isLoggedIn ){
			this.doAuthCheck();
		}
		
	}

	componentWillReceiveProps(nextProps){
		
		if( this.props.showGlobalLoginModal === true && nextProps.isLoggedIn === true ){
			this.props.hideLoginModal();
			this.setState({loginMessage:''});
		}

		if( this.props.requireAuthCheck === false && nextProps.requireAuthCheck === true ){
			this.doAuthCheck();
		}
	}

	loginNavClick(event){
		const value = event.target.value;
		switch(value){
			case 'Forgot Password?':
				this.setState({currentForm: 'forgotPassword'});
				break;
			case 'Register':
				this.props.history.push('/account/register');
				this.props.hideLoginModal();
				break;
			case 'Login':
				this.setState({currentForm: 'login'});
				break;
			default:
			 	throw new Error(`loginNavClick was called with an unkonwn button type: ${value}`)
		}
	}


	render() {

		let modalForm = null;
		let navItems = [];

		switch( this.state.currentForm ){
			case 'login':
				modalForm = <Login loginMessage={this.state.loginMessage}/>;
				navItems = [
					'Forgot Password?',
					'Register'
				]
				break;
			case 'forgotPassword':
				modalForm = <ForgotPassword />;
				navItems = [
					'Login',
					'Register'
				]
				break;
			case 'register':
				modalForm = <Register />;
				navItems = [
					'Login',
					'Forgot Password?',
				]
				break;
			default:
				throw new Error(`Login Modal state has an unkonwn currentForm: ${this.state.currentForm}`);
		}

		return (
			<Modal 
				isVisible={ this.props.showGlobalLoginModal }
				contentType={null}
				doCloseModal={ this.closeLoginModal }
				selectedStations={ null }
				modalContent={ 
					<div className="login-modal">
						<button 
							className="closeModalBtn" 
							onClick={ this.closeLoginModal }
							autoFocus
						>
							<SvgIcon icon="menu-close" />
						</button>
						
						{modalForm}

						<ul className="piped-nav login-menu">
							{ 
								navItems.map((item,index) => {
									return <li className="piped-nav-item" key={`login-modal-nav-${index}`}><button className="btn-link" value={item} onClick={this.loginNavClick}>{item}</button></li>
								})
							}
						</ul>
			
					</div>
				}
			/>
		)
	}
}

function mapStateToProps(state) {
	return { 
        showGlobalLoginModal: state.auth.showGlobalLoginModal,
        requireAuthCheck: state.auth.requireAuthCheck,
        isLoggedIn: state.auth.isLoggedIn,
        userId: state.auth.userId,
        auth: state.auth,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
    	hideLoginModal : hideLoginModal,
		showLoginModal : showLoginModal,
		login: login,
		logout: logout,
		setAvailableDashboards: setAvailableDashboards
    }, dispatch)    
}


export default withRouter( connect( mapStateToProps, matchDispatchToProps )( LoginModal )); 
