function get_default_state(){

    return  {
        //state
        showGlobalLoginModal: false,
        requireAuthCheck: false,
        rememberMe: null,

        //user token
        token: null,

        //user profile
        userId: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        organizationName: null,

        // permisions
        isLoggedIn: false,
        permissions: [],
        canAccessDocumentLibrary: false,
        canAccessBatteryData: false,
        canAcccesStandardReports: false,
        canAcccessForcastedData: false,
        canAccessDamOperationsLog: false,
        canAccessDamCalculatorTools: false,
        canAccessFloodVulerableClusters: false,
        canAccessCustomDashboards: false,
        canAccessThirdPartyData: false,
    }
}
export default function ( state=get_default_state(), action ) {
    switch( action.type ) {

        case "LOGIN":

            let newState = {
                // original state
                ...state,

                //update profile fields
                userId: action.payload.userId,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phone: action.payload.phone,
                organizationName: action.payload.organizationName,


                // token
                token:action.payload.token, 

                // logins state
                isLoggedIn: true, 
                rememberMe: ( action.payload.rememberMe !== undefined ? action.payload.rememberMe : state.rememberMe ),
                requireAuthCheck: false,


                // permissions
                canAccessDocumentLibrary : (action.payload.permissions.indexOf( 'can_access_document_library' ) === -1 ? false : true ),
                //canAccessBatteryData : (action.payload.permissions.indexOf( 'can_access_battery_data' ) === -1 ? false : true ),
                canAcccesStandardReports : (action.payload.permissions.indexOf( 'can_access_standard_storm_reports' ) === -1 ? false : true ),
                canAcccessForcastedData : (action.payload.permissions.indexOf( 'can_access_forecasted_data' ) === -1 ? false : true ),
                canAccessDamOperationsLog : (action.payload.permissions.indexOf( 'can_access_dam_operations_log' ) === -1 ? false : true ),
                canAccessDamCalculatorTools : (action.payload.permissions.indexOf( 'can_access_dam_calculator_tools' ) === -1 ? false : true ),
                canAccessFloodVulerableClusters : (action.payload.permissions.indexOf( 'can_access_flood_vulnerable_clusters' ) === -1 ? false : true ),
                canAccessCustomDashboards : (action.payload.permissions.indexOf( 'can_access_custom_dashboards' ) === -1 ? false : true ),
                canAcesssThirdPartyData : (action.payload.permissions.indexOf( 'can_access_third_party_data' ) === -1 ? false : true )

            }
            //console.log(newState);
            return newState;

        case "LOGOUT":
            return get_default_state();
        case "SHOW_LOGIN_MODAL":
            return {...state, showGlobalLoginModal: true};
        case "HIDE_LOGIN_MODAL":
            return {...state, showGlobalLoginModal: false};
    	case "DO_AUTH_CHECK":
            return {...state, requireAuthCheck: true};
        default:
        	return state;
    }

}