import React, { Component } from "react";
import SvgIcon from "./SvgIcon";
import { setTimeout } from "timers";
import {getRoute, apiRequest} from "../helpers/Api";

class TwitterUpdateBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tweetId: null,
            tweet: null,
            tweetUrl: null,
            createdAt: null
        }

        this.latestTweetInterval = null;   
        this.fetchLatestTweet = this.fetchLatestTweet.bind(this);

    }

    componentDidMount() {

        // try to get the tweet from local storage
        // for initial display
        try {
            var localTweet = JSON.parse( localStorage.getItem('storedTweetJson') );
        } catch(e) {
            var localTweet = null;
        }

        // if we have something in local storage display it
        if( localTweet !== null ){
            this.setState({...localTweet});
        }

        // get the latest tweets
        this.fetchLatestTweet();

        // set a timer to refresh the tweets
        this.latestTweetInterval = setInterval( this.fetchLatestTweet, 300000 ); 

    }

    componentWillUnmount() {
        // clear the timer for tweets
        clearInterval(this.latestTweetInterval);
    }

    fetchLatestTweet() {
        // fetch the latest tweet
        apiRequest(getRoute("latestTweet"))
            .then(result => {

                // build new state
                const newState = {
                    tweet: result.text,
                    tweetId: result.id_str,
                    createdAt: result.created_at,
                    tweetUrl: result.tweet_url
                }

                this.setState(newState);

                // keep a copy of the state in local storage
                localStorage.setItem('storedTweetJson',JSON.stringify(newState));

            });
    }
    
    render() {

        // if no tweet, don't show the bar
        if( this.state.tweetId == null ){
            return null;
        }  


        // get unix timestamp in seconds
        const tweetDate = new Date(this.state.createdAt).getTime() / 1000;
        const curTime = new Date().getTime() / 1000;

        // if the current tweet is older than 3 days 
        // don't show it
        if(  (curTime - tweetDate) >  259200 ){
            return null;
        }


        // show the tweet
        return (
            <div className="notification" >
                <SvgIcon icon={ "twitter" }/><p>{ this.state.tweet }<a href={this.state.tweetUrl} target="_blank" rel="noopener noreferrer"> see more</a></p>    
            </div>
        );
    }
}

export default TwitterUpdateBar;

 