import React, { Component } from "react";
import Field from "../forms/Field";
import { connect } from "react-redux";

//import SvgIcon from "./SvgIcon";

class MapMenu extends Component {
    constructor ( props ) {
        super( props ); 

        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        
    }

    handleInputChange(value, field, event) {
        var checkboxId = event.target.id;
        var checkboxStatus = event.target.checked;
        //console.log( checkboxId );
        //console.log( this.props );
        if( this.props.menuStatus.rainGauges === "off" && checkboxId === "precipitation-bars" ) {
            if( checkboxStatus ) {
                this.props.inputChangedData("rain-gauges", "on");
            }
        }

        this.props.inputChangedData(checkboxId, checkboxStatus);


    }

    componentWillUpdate(nextProps){
        if( this.props.canAccessFloodVulerableClusters === true && nextProps.canAccessFloodVulerableClusters === false ){
            this.props.inputChangedData('flood-plain', false);
        }
    }

    render() {

        console.log(this.props.menuStatus);

        let watershedChecked = (this.props.menuStatus.watershedBoundaries === "on") ? true : false;
        let floodPlainChecked = (this.props.menuStatus.floodPlain === "on") ? true : false;
        let floodLineChecked = (this.props.menuStatus.floodLine === "on") ? true : false;
        let damGaugesChecked = (this.props.menuStatus.damGauges === "on") ? true : false;
        let streamGaugesChecked = (this.props.menuStatus.streamGauges === "on") ? true : false;
        let rainGaugesChecked = (this.props.menuStatus.rainGauges === "on") ? true : false;
        let weatherRadarChecked = (this.props.menuStatus.weatherRadar === "on") ? true : false;
        let precipitationBarsChecked = (this.props.menuStatus.precipitationBars === "on") ? true : false;
        let thirdPartyChecked = (this.props.menuStatus.thirdPartyData === "on") ? true : false;

        return(
            <div>
                <fieldset>
                    <legend className="slideout-heading" >Show me:</legend> 

                        <Field 
                            id="watershed-boundaries" 
                            type="checkbox" 
                            name="filters" 
                            value="watershed-boundaries" 
                            defaultChecked={ watershedChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Watershed Boundaries"
                        />
                        
                        {  this.props.canAccessFloodVulerableClusters && 

                            <div>
                                <Field 
                                    id="flood-plain" 
                                    type="checkbox" 
                                    name="filters" 
                                    value="flood-plain" 
                                    defaultChecked={ floodPlainChecked }
                                    onChange={ this.handleInputChange }
                                    required={false}
                                    label="Flood Plain"
                                />

                                <Field 
                                    id="flood-line" 
                                    type="checkbox" 
                                    name="filters" 
                                    value="flood-line" 
                                    defaultChecked={ floodLineChecked }
                                    onChange={ this.handleInputChange }
                                    required={false}
                                    label="Flood Vulnerable Area"
                                />
                            </div>
                        }
                        
                        <Field 
                            id="dam-gauges" 
                            type="checkbox" 
                            name="filters" 
                            value="dam-gauges" 
                            defaultChecked={ damGaugesChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Dam Gauges"
                        />
                        

                        <Field 
                            id="stream-gauges" 
                            type="checkbox" 
                            name="filters" 
                            value="stream-gauges" 
                            defaultChecked={ streamGaugesChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Stream Gauges"
                        />
                        

                        <Field 
                            id="rain-gauges" 
                            type="checkbox" 
                            name="filters" 
                            value="rain-gauges"
                            checked={true} 
                            defaultChecked={ rainGaugesChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Rain Gauges"
                        />
                        
 
                        <Field 
                            id="weather-radar" 
                            type="checkbox" 
                            name="filters" 
                            value="weather-radar" 
                            defaultChecked={ weatherRadarChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Weather Radar"
                        />
                        

                        <Field 
                            id="precipitation-bars" 
                            type="checkbox" 
                            name="filters" 
                            value="precipitation-bars" 
                            defaultChecked={ precipitationBarsChecked }
                            onChange={ this.handleInputChange }
                            required={false}
                            label="Precipitation Bars"
                        />

                        { this.props.canAcesssThirdPartyData &&

                            <Field 
                                id="third-party-data" 
                                type="checkbox" 
                                name="filters" 
                                value="third-party-data" 
                                defaultChecked={ thirdPartyChecked }
                                onChange={ this.handleInputChange }
                                required={false}
                                label="Third Party Gauges"
                            />
                        }
          
                </fieldset>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { 
        canAccessFloodVulerableClusters: state.auth.canAccessFloodVulerableClusters,
        canAcesssThirdPartyData: state.auth.canAcesssThirdPartyData
    };
}


export default connect( mapStateToProps )( MapMenu ); 