import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import SvgIcon from '../components/SvgIcon';
import { 
	toggleNavMenu, 
	setNavItems,
	setActiveBuildMenuType 
} from '../actions/navigation';
import { setBuildMode } from "../actions/Dashboard";
import DashboardBuildMenu from "./dashboard/DashboardBuildMenu";
import DashboardMultiMenu from "./dashboard/DashboardMultiMenu"


// A nav item component
class NavMenu extends Component {

	renderNavItem(item){ 

		let className = "menu-item-wrap";
		let icon = null;
		if( item.icon !== undefined){
			icon = <SvgIcon icon={item.icon}></SvgIcon>
			className += " menu-item-has-icon";
		}

		let navItem = null;
		if( item.onClick !==  undefined ){
			navItem = <a className={`menu-item`} href={null} onClick={item.onClick}>{icon}{item.name}</a>
		} else if(item.id === 2) {
			navItem = <a className={`menu-item`} href="/reports" onClick={item.onClick}>{icon}{item.name}</a>
		}
		else{
			navItem = <Link className={`menu-item`} to={item.link}>{icon}{item.name}</Link>
		}

		return (
			<li className={className} key={item.id}>
				{navItem}
			</li>	
		)
	}

	render() {
		return (
			<ul className={this.props.menuClassName}>
				{ this.props.navItems.map(this.renderNavItem) }
			</ul>	
		)
	}
};


// A container for the header menu
class HeaderMenu extends Component { 

	constructor (props) {
        super(props);
        this.getClassName = this.getMobileClass.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.generateNavItems = this.generateNavItems.bind(this);
		this.toggleBuildMode = this.toggleBuildMode.bind(this);
		this.dashboardBuildMenuItemSelected = this.dashboardBuildMenuItemSelected.bind(this);
        
    }

    // Update the nav items on initial mount
    componentWillMount(){
    	this.generateNavItems(this.props);
    }

    // watch for changes for the user access restrictions and update acordingly
    componentWillReceiveProps(nextProps){

    	//console.log('componentWillReceiveProps - document', this.props.canAccessDocumentLibrary, nextProps.canAccessDocumentLibrary);
    	//console.log('componentWillReceiveProps - dashboard', this.props.canAccessCustomDashboards, nextProps.canAccessCustomDashboards);

    	let requireUpdate = false;
    	if(this.props.canAccessDocumentLibrary !== nextProps.canAccessDocumentLibrary){
    		requireUpdate = true;
    	}

    	if(this.props.canAccessCustomDashboards !== nextProps.canAccessCustomDashboards){
    		requireUpdate = true;
    	}

    	if( this.props.dashboardVisable !== nextProps.dashboardVisable ){
    		requireUpdate = true;
    	}

    	if( requireUpdate ){
    		this.generateNavItems(nextProps);
    	}


    }


    generateNavItems(theProps){

    	let navItems = [
    		 {
				"id": 1,
				"name" : "Station Map",
				"link" : ( theProps.canAccessCustomDashboards ? "/station-map" : "/" ),
				"icon" : 'menu-station-map',
			},
			{
				"id": 2,
				"name" : "Reports",
				"link" : "/reports",
				"icon" : 'menu-reports',
			},
			{
				"id": 3,
				"name" : "Data Explorer",
				"link" : "/data-explorer",
				"icon" : 'menu-data-explorer',
			}
    	];

    	if( theProps.canAccessDocumentLibrary ){
    		navItems.push({
				"id": 4,
				"name" : "Documents",
				"link" : "/documents",
				"icon" : 'menu-documents',
			});
		}

		if( theProps.canAccessDamOperationsLog ){
    		navItems.push({
				"id": 6,
				"name" : "Dam Ops",
				"link" : "/dam-ops",
				"icon" : 'dashboard-dam-gauge',
			});
		}
		

    	this.props.setNavItems(navItems);
    }

    toggleBuildMode(event){
    	event.preventDefault();
    	this.props.setBuildMode( !this.props.buildModeActive );
    }

	// Gets the class name based on the state
	getMobileClass(){
		if(this.props.mainNavigation.visible === true){
			return 'menu-open';
		}
		else{
			return 'menu-hidden';
		}
	}

	// Toggles the state of the nav
	toggleNav() {
	    this.props.toggleNavMenu();
	}

	getBuildOptions(){

	}

	getDashboardOptions(){

	}

	dashboardBuildMenuItemSelected( type ) {
		this.props.setActiveBuildMenuType(type);
	}


	render() {
		//console.log('rendering', this.props);


		let buildModeMenu = null;
		let buildModeButton = null;
		let dashboardMultiMenu = null;

		if( this.props.dashboardVisable ){

			buildModeButton = 
			<ul className="menu-buttons build-mode-button">
        		<li className="menu-item-wrap menu-item-has-icon">
        			<button className="menu-item" onClick={this.toggleBuildMode} tabIndex="0"><SvgIcon icon="menu-dashboard" />Build Mode</button>
        		</li>
        	</ul>;

        	dashboardMultiMenu = <DashboardMultiMenu/>

			if( this.props.buildModeActive ){
				buildModeMenu = 
				<DashboardBuildMenu
					handleShowBuildMenuDialogue={ this.dashboardBuildMenuItemSelected }
				/>
			}
		}

		return (
			<div className="header-wrap">
				<button className={`menu-btn ${this.getMobileClass() }`} id="morebtn" onClick={this.toggleNav}>
	                <span className="menu-btn-text more-menu-open">
	                    <SvgIcon icon="menu" /> Menu
	                </span>
	                <span className="menu-btn-text more-menu-close">
	                    <SvgIcon icon="menu-close" /> Close
	                </span>
	            </button>

	            <div className={`main-navigation ${this.getMobileClass()}`} role="navigation" aria-labelledby="main-header-nav">
					<h2 className="screen-reader-text" id="main-header-nav">Main site navigation</h2>
	            	<NavMenu navItems={this.props.mainNavigation.navItems} menuClassName={`menu-buttons`} />

	            	{ buildModeButton }

	            	{ buildModeMenu }

	            	{dashboardMultiMenu}

	            	
	            </div>
	            
	        </div>

		)
	}
}


function mapStateToProps(state) {
	return { 
		mainNavigation: state.mainNavigation,
		canAccessDocumentLibrary: state.auth.canAccessDocumentLibrary,
		canAccessCustomDashboards: state.auth.canAccessCustomDashboards,
		canAccessDamOperationsLog: state.auth.canAccessDamOperationsLog,
		dashboardVisable: state.dashboard.dashboardVisable,
		buildModeActive: state.dashboard.buildModeActive,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		toggleNavMenu: toggleNavMenu,
		setNavItems: setNavItems,
		setBuildMode: setBuildMode,
		setActiveBuildMenuType: setActiveBuildMenuType,

	}, dispatch)
}

export default connect( mapStateToProps, matchDispatchToProps )( HeaderMenu ); 