import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";

import DayPicker, { DateUtils } from "react-day-picker";
import Preloader from "../components/Preloader";
import SlideoutPanel from "../components/SlideoutPanel";
import CheckboxList from "../components/slideout-menu/CheckboxList";
import ErrorMsg from "../components/ErrorMsg";
import DataExplorerMap from "../components/data-explorer/DataExplorerMap";
import SelectedGaugeList from "../components/data-explorer/SelectedGaugeList";
import SelectGaugeDropdown from "../components/data-explorer/SelectGaugeDropdown";
import PageWrapper from "../components/PageWrapper";
import { Field, PhoneField } from "../components/forms/Field";
import {getRoute, apiRequest } from "../helpers/Api";
import YearToForm from "../components/YearToForm";
import { 
    setSlideoutOpen,
    updateAllStations,
    updateAllFilters,
    setFilter,
    selectGauges,
    deselectGauges,
    deselectAllGauges,
    selectAllGauges,
    updateContactFormField,
    validateAllFields,
    submittingRequest,
    submitSuccess,
    submitFail,
    handleDataExplorerTimeRangeSelection,
    resetDataExplorerData,
    setMonthForDatepickerDropdown,

} from "../actions/DataExplorer";

import { 
    showFetchError,
} from "../actions/index";

import { withinDateRange, yyyymmdd } from '../helpers/gaugeHelpers';
import { 
    togglePreloader,
} from "../actions/index";

import 'react-day-picker/lib/style.css';

class DataExplorerContainer extends Component {
    constructor ( props ) {
        super( props ); 

        //methods
        this.toggleSlideout = this.toggleSlideout.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getDataFromWebservice = this.getDataFromWebservice.bind(this);
        this.getFilteredStations = this.getFilteredStations.bind(this);
        this.filterTimeRange = this.filterTimeRange.bind(this);
        this.addGauges = this.addGauges.bind(this);
        this.removeGauges = this.removeGauges.bind(this);
        this.getSelectedGaugesSection = this.getSelectedGaugesSection.bind(this);
        this.getStationSelectbox = this.getStationSelectbox.bind(this);
        this.changeContactFieldEvent = this.changeContactFieldEvent.bind(this);
        this.changeContactFieldEventNew = this.changeContactFieldEventNew.bind(this);
        this.handleContactSubmit = this.handleContactSubmit.bind(this);
        this.getContactForm = this.getContactForm.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.renderSubmissionPage = this.renderSubmissionPage.bind(this);        
        this.renderThankYouPage = this.renderThankYouPage.bind(this);
        this.isFetching = this.isFetching.bind(this);
        this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
        this.handleBackToExplorer = this.handleBackToExplorer.bind(this);

        let initialState = {
            scrollToError: false,
            showingPreloader: false,
            submissionSuccess: false,
        }

        // these are keys used to track if various items are fetching throughout the app
        this.fetchKeys = ['fetchingStations', 'fetchingFilters', 'fetchingSubmission'];
    
        for (let i = 0; i < this.fetchKeys.length; i++) {
            initialState[this.fetchKeys[i]] = false;
        }

        this.state = initialState;

    }


    componentWillMount(){
        this.props.resetDataExplorerData();
        window.scrollTo(0, 0);

        if(this.props.userInfo.isLoggedIn){        
            this.props.updateContactFormField("first_name", this.props.userInfo.firstName);
            this.props.updateContactFormField("last_name", this.props.userInfo.lastName);
            this.props.updateContactFormField("email", this.props.userInfo.email);
            this.props.updateContactFormField("phone", this.props.userInfo.phone);
            this.props.updateContactFormField("organization_name", this.props.userInfo.organizationName);
        }
    }


    componentDidMount(){
        this.getDataFromWebservice();

        let thisYear = new Date().getFullYear();
        let thisMonth = new Date().getMonth()+1;
		this.props.setMonthForDatepickerDropdown( new Date(thisYear, thisMonth, 0) );

    }

    // loops over the keys and checks to see if any of are true, if not returns false
    isFetching(){
        for (var i = 0; i < this.fetchKeys.length; i++) {
            if( this.state[this.fetchKeys[i]] === true ){
                return true;
            }
        }
        return false;
    }

    componentDidUpdate(){

        if( this.state.scrollToError === true ){
            const errors = document.getElementsByClassName('form-field-validation-error');
            if( errors.length ){
                errors[0].scrollIntoView();
            }
            this.setState({'scrollToError': false});
        }

        //set the loading basd on the fetching state
        if( !this.state.showingPreloader && this.isFetching() ){
            //show the preloader
            this.setState({'showingPreloader': true});
            this.props.togglePreloader(true);
        }
        else if( this.state.showingPreloader && !this.isFetching() ){
            // hide the preloader
            this.setState({showingPreloader:false});
            this.props.togglePreloader(false);
        }

    }

    getDataFromWebservice() {

        this.setState({
            fetchingStations : true,
            fetchingFilters : true
        });

        //fetch the data from the api
        apiRequest(getRoute('dataExplorerStations'))
            .then(result => {
                //console.log( 'station fetch complete');
                this.props.updateAllStations( result );
                //this.props.togglePreloader(false);
                this.setState({fetchingStations: false});
            })
            .catch( (error) => {
                //console.log( 'Error', error );
			    this.props.togglePreloader(false); 
                /*let errorMsg = "";
                switch(error) {
                    default:
                    errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                    break;
                }
                this.props.togglePreloader(false);
                var obj = {
                    isVisible: true,
                    message: errorMsg,
                };
    
                this.props.showFetchError( obj );*/
            })

        apiRequest(getRoute('dateExplorerSubNetworks'))
            .then(result => {
                this.props.updateAllFilters( result );
                this.setState({fetchingFilters: false});
                //this.props.togglePreloader(false);
            })
            .catch( (error) => {
                //console.log( 'Error', error );
			    this.props.togglePreloader(false); 
                /*let errorMsg = "";
                switch(error) {
                    default:
                    errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                    break;
                }
                this.props.togglePreloader(false);
                var obj = {
                    isVisible: true,
                    message: errorMsg,
                };
    
                this.props.showFetchError( obj );*/
            })


    }



    toggleSlideout(){

        this.props.dataExplorer.slideoutOpen ? this.props.setSlideoutOpen(false) : this.props.setSlideoutOpen(true)
    }

    handleFilterChange(event){
        var filtedClicked = event.target.value;
        var checkboxStatus = event.target.checked;
        this.props.setFilter(filtedClicked, checkboxStatus);
    }

    getFilteredStations(stations){

        //get the applied filters
        //console.log(this.props); 

         let selectedFilters = [];
        for (var f = 0; f < this.props.dataExplorer.filters.length; f++) {
            //console.log( this.props.dataExplorer.filters[f].selected );
            if( this.props.dataExplorer.filters[f].selected === true ){
                selectedFilters.push(this.props.dataExplorer.filters[f].value)
            }
        }

        // set filtred stationst o be all stations for now
        let filtedStations = stations;
        
        // if filters are selected modit
        if( selectedFilters.length ){
            filtedStations = [];

            //loop over the stations
            for (var i = 0; i < stations.length; i++) {
                const theStation = stations[i];

                //add the gauges that are part of the selected filters
                let gauges = [];
                for (var b = 0; b < theStation.gauges.length; b++) {
                    if( selectedFilters.indexOf(theStation.gauges[b].subNetwork) !== -1 ){
                        gauges.push( theStation.gauges[b] );
                    }
                }

                //only add the station if the gauge is filtered
                if( gauges.length ){
                    filtedStations.push({...theStation, ...{gauges:gauges}});
                }
            }

        }

        return filtedStations;

    }



    filterTimeRange(stations){
        //if no filter just return the stations
        if( this.props.dataExplorer.dateTime.range.from === null || this.props.dataExplorer.dateTime.range.to === null ){
            return stations;
        }

        const rangeFrom = this.props.dataExplorer.dateTime.range.from;
        const rangeTo = this.props.dataExplorer.dateTime.range.to;
        
        let filtedStations = [];

        //loop over the stations
        for (var i = 0; i < stations.length; i++) {
            const theStation = stations[i];

            //add the gauges that are part of the selected filters
            let gauges = [];
            for (var b = 0; b < theStation.gauges.length; b++) {
                //console.log( theStation.gauges[b].startDate, theStation.gauges[b].endDate, rangeFrom, rangeTo );
                const withinRange = withinDateRange(theStation.gauges[b].startDate, theStation.gauges[b].endDate, rangeFrom, rangeTo);
                if( withinRange || !withinRange || theStation.gauges[b].selected ){

                    gauges.push( { ...theStation.gauges[b], 'withinRange': withinRange });
                }
                
            }

            //only add the station if the gauge is filtered
            if( gauges.length ){
                filtedStations.push({...theStation, ...{gauges:gauges}});
            }
        }


        return filtedStations;
    }

    addGauges(gauges){
        this.props.selectGauges( gauges );
    }
    removeGauges(gauges){
        this.props.deselectGauges( gauges );
    }



    getSelectedGaugesSection( filteredData ){
        let selectedStations = [];

        const rangeFrom = this.props.dataExplorer.dateTime.range.from;
        const rangeTo = this.props.dataExplorer.dateTime.range.to;

        for (var i = 0; i < this.props.dataExplorer.stations.length; i++) {
            const theStation = this.props.dataExplorer.stations[i];
            let gauges = [];
            for (var b = 0; b < theStation.gauges.length; b++) {
                if( theStation.gauges[b].selected ){

                    let tooltip = null;
                    let className = '';

                    if( rangeFrom !== null && rangeTo !== null ){
                        //gauges do NOT need to be checked unless a selected date has been chosen
                        const withinRange = withinDateRange(theStation.gauges[b].startDate, theStation.gauges[b].endDate, rangeFrom, rangeTo);

                        if( withinRange !== 1 ){

                            let fromClass = 'gauge-outside-range-from';
                            let toClass = 'gauge-outside-range-to'

                            if( withinRange === 0 ){
                                //both are outside range
                                fromClass = fromClass + ' outside-range';
                                toClass = toClass + ' outside-range';
                            }
                            else if( withinRange === 2){
                                //range extends the begining of the data set
                                fromClass = fromClass + ' outside-range';
                            }
                            else if( withinRange === 3){
                                //date range of the selected dates extend the end of gauge
                                toClass = toClass + ' outside-range';
                            }

                            tooltip = (
                                <div className="gauge-outside-range">
                                    <p className="gauge-outside-range-title">Available data for this gauge is outside of the selected date range.</p>

                                    <span className="gauge-outside-range-times">
                                        <p className="gauge-outside-time-title">Avaialble dates:</p>
                                        <span className={fromClass}>{yyyymmdd(theStation.gauges[b].startDate)}</span> - <span className={toClass}>{yyyymmdd(theStation.gauges[b].endDate)}</span>
                                    </span>
                                </div>
                            );
                            className = 'gauge-outside-range';  
                        }
                    }


                    gauges.push(  {
                        id: theStation.gauges[b].id,
                        sensorName: theStation.gauges[b].sensorName,
                        removeable: true,
                        tooltip: tooltip,
                        className: className,
                    });
                }
            }
            //only add the station if the gauge is filtered
            if( gauges.length ){

                let station = {
                    id: theStation.id,
                    removeable: true,
                    stationName: theStation.stationName,
                    gauges: gauges
                }

                selectedStations.push(station);
            }
        }


        return (
            <div className="selected-stations">
                <h3 className="slideout-heading">Your Data Set</h3>
                <SelectedGaugeList 
                    selectedStations={selectedStations}
                    stationsOnly={false}
                    removeGauges={this.removeGauges}
                    noneSelectedMessage="No gauges selected"
                />
            </div>
        );
    }

    getStationSelectbox( filteredStations ){

        let stationData = [];
        for (var i = 0; i < filteredStations.length; i++) {
            const theStation = filteredStations[i];
            let gauges = [];
            let stationHasSelectedGaugeOutsideRange = false;
            for (var b = 0; b < theStation.gauges.length; b++) {

                let selectedClass = '';
                if( theStation.gauges[b].selected && theStation.gauges[b].withinRange !== 1 && theStation.gauges[b].withinRange !== undefined ){
                    selectedClass += ' gauge-out-of-range';
                    stationHasSelectedGaugeOutsideRange = true;
                }

                let fromClass = '';
                let toClass = '';


                if( theStation.gauges[b].withinRange === 0 ){
                    //both are outside range
                    fromClass = fromClass + ' outside-range';
                    toClass = toClass + ' outside-range';
                }
                else if( theStation.gauges[b].withinRange === 2){
                    //range extends the begining of the data set
                    fromClass = fromClass + ' outside-range';
                }
                else if( theStation.gauges[b].withinRange === 3){
                    //date range of the selected dates extend the end of gauge
                    toClass = toClass + ' outside-range';
                }

                const gaugeDescription = <span className="gauge-time"><span className={fromClass}>{yyyymmdd(theStation.gauges[b].startDate)}</span> to <span className={toClass}>{yyyymmdd(theStation.gauges[b].endDate)}</span></span>;

                gauges.push(  {
                    id: theStation.gauges[b].id,
                    sensorName: theStation.gauges[b].sensorName,
                    selected: theStation.gauges[b].selected,
                    searchableString: theStation.gauges[b].searchableString, 
                    gaugeDescription: gaugeDescription,
                    className: selectedClass,
                });       
            }

            let station = {
                id: theStation.id,
                stationName: theStation.stationName,
                gauges: gauges,
                className: (stationHasSelectedGaugeOutsideRange ? 'has-gauge-outside-range' : '' )
            }

            stationData.push(station);
            
        }


        return (
            <SelectGaugeDropdown
                inputId="data-station-search" 
                stationsOnly={false} 
                stations={stationData}
                addGauges={this.addGauges}
                allowSelectAll={true}
                removeGauges={this.removeGauges}
                noGaugesAvailableText="No gauges available for your specified date range"
            />
        ) 
    }

    changeContactFieldEventNew(value, field){

        if( field === "has_signed_tos" ){
            this.props.updateContactFormField(field, value);
        }
        else{
            this.props.updateContactFormField(field, value);
        }

    }

    changeContactFieldEvent(event){
        //console.log( event.target.getAttribute('name'), event.target.value );
        if( event.target.getAttribute('name') === "has_signed_tos" ){
            this.props.updateContactFormField(event.target.getAttribute('name'), event.target.checked);
        }
        else{
            this.props.updateContactFormField(event.target.getAttribute('name'), event.target.value);
        }
    }

    handleContactSubmit(event){
        event.preventDefault();
        this.props.validateAllFields();
        const formKeys = Object.keys( this.props.dataExplorer.contactFields );
        var fieldsValid = true;
        let contactFields = {};

        for (var i = 0; i < formKeys.length; i++) {

            if( this.props.dataExplorer.contactFields[ formKeys[i] ].isValid !== true ){
                fieldsValid = false;
                break;
            }
            contactFields[ formKeys[i] ] = this.props.dataExplorer.contactFields[ formKeys[i] ].value;
        }


        if( fieldsValid ){
            this.props.submittingRequest();
            contactFields.phone =  contactFields.phone.replace(/[- ().]/g, '');
            this.setState({fetchingSubmission: true});
            apiRequest(getRoute('dataRequests'), {
                  method: 'POST',
                  body: JSON.stringify(contactFields)
                })
                .then(result => {
                    //console.log( this.props );
                    //console.log('results', result);
                    this.setState({fetchingSubmission: false});
                    if( result.statusText === "Created" ){
                        this.props.submitSuccess();
                        this.setState({ submissionSuccess: true });
                    }
                    else{
                        this.props.submitFail();
                        this.setState({ submissionSuccess: false });
                    }
                })
                .catch(result => {
                    this.setState({fetchingSubmission: false});
                    this.setState({ submissionSuccess: false });
                    this.props.submitFail();
                });
        }
        else{
             this.setState({'scrollToError': true});
            //alert('data not sent!')
        }

    }

    dismissErrorMessage() {
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
	}

    getContactForm(output){

        //submit request message
        let submitMessage = null;
        if( this.props.dataExplorer.submissionSuccess === true ){
            //submitMessage = <div className="submission-message-success">Thank you we have recieved your request.</div>
        }
        else if( this.props.dataExplorer.submissionSuccess === false){
            submitMessage = <div className="submission-message-error">Sorry, we were unable to process your request at this time. Please try again later.</div>
        }



        let submitButton = <input className="report-btn" type="submit" name="Submit" value="Submit" />
        if( this.props.dataExplorer.submittingRequest ){
            submitButton = <input className="report-btn" type="submit" name="Submit" value="Sending request..." disabled="disabled" />
        }

        /*
        <Field 
                    name="has_signed_tos" 
                    id="data-request-has_signed_tos" 
                    label="I agree to the TOS" 
                    type="checkbox" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.has_signed_tos.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.has_signed_tos.value}
                    defaultChecked={this.props.dataExplorer.contactFields.has_signed_tos.value}
                    validationMessage={this.props.dataExplorer.contactFields.has_signed_tos.showMessage}
                />
        */

        return (
            <form id="data-request" onSubmit={this.handleContactSubmit} >
                <h3>Contact Information</h3>
                
                <input type="hidden" id="data-request-notes" name="notes" value={this.props.dataExplorer.contactFields.notes.value} />

                <Field 
                    name="first_name" 
                    id="data-request-first-name" 
                    label="First Name" 
                    type="text" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.first_name.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.first_name.value}
                    defaultValue={ this.props.dataExplorer.contactFields.first_name.value }
                    validationMessage={this.props.dataExplorer.contactFields.first_name.showMessage} />
                

                 <Field 
                    name="last_name" 
                    id="data-request-last-name" 
                    label="Last Name" 
                    type="text" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.last_name.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.last_name.value}
                    defaultValue={ this.props.dataExplorer.contactFields.last_name.value }
                    validationMessage={this.props.dataExplorer.contactFields.last_name.showMessage} />

                <Field 
                    name="email" 
                    id="data-request-email" 
                    label="Email" 
                    type="email" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.email.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.email.value}
                    defaultValue={ this.props.dataExplorer.contactFields.email.value }
                    validationMessage={this.props.dataExplorer.contactFields.email.showMessage} 
                    maxLength={60}
                    />


                <PhoneField 
                    name="phone" 
                    id="data-request-phone" 
                    label="Phone" 
                    type="phone" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.phone.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.phone.value}
                    validationMessage={this.props.dataExplorer.contactFields.phone.showMessage}
                    mask="999-999-9999"
                    defaultValue={ this.props.userInfo.phone }/>
                    

                <Field 
                    name="phone_extension" 
                    id="data-request-phone_extension" 
                    label="Extension" 
                    type="text" 
                    required={false} 
                    isValid={( this.props.dataExplorer.contactFields.phone_extension.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.phone_extension.value}
                    validationMessage={this.props.dataExplorer.contactFields.phone_extension.showMessage} />


                <Field 
                    name="organization_name" 
                    id="data-request-organization_name" 
                    label="Organization Name" 
                    type="text" 
                    required={false} 
                    isValid={ true } 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.organization_name.value}
                    defaultValue={ this.props.dataExplorer.contactFields.organization_name.value }
                    validationMessage={this.props.dataExplorer.contactFields.organization_name.showMessage} />


                <Field 
                    name="data_usage" 
                    id="data-request-data_usage" 
                    label="Data Useage" 
                    type="textarea" 
                    required={true} 
                    isValid={( this.props.dataExplorer.contactFields.data_usage.showMessage === '' ? true : false )} 
                    onChange={ this.changeContactFieldEventNew } 
                    value={this.props.dataExplorer.contactFields.data_usage.value}
                    validationMessage={this.props.dataExplorer.contactFields.data_usage.showMessage} />

                
                <p>Data accessible through this website are made available under the<br/><a href="https://trca.ca/about/open-data-licence/" target="_blank" rel="noopener noreferrer">TRCA Open Data License.</a></p>

                              
                {submitButton}

                {submitMessage}

            </form>
        )
        


    }

    handleDayClick(day, { selected, disabled }){
        if(disabled){
            // Day is disabled do nothing
            return;
        }
        
        var rangeObj = {
            from: this.props.dataExplorer.dateTime.range.from,
            to: this.props.dataExplorer.dateTime.range.to,
        };

        const range = DateUtils.addDayToRange(day, rangeObj);
        this.props.handleDataExplorerTimeRangeSelection(range);
    };

    handleYearMonthChange(month) {
        //let prevMonth = new Date();
		//month.setDate(1);
		//month.setMonth(month.getMonth() - 1);

		this.props.setMonthForDatepickerDropdown( month );
	}

    renderSubmissionPage(){
        // Filters
        const filters = this.props.dataExplorer.filters.map( ( item, index ) => { 
            return {
                id:'gauge-filter-' + item.value, 
                displayName: item.displayName, 
                value: item.value, 
                selected: item.selected
            }
        })

        let filteredStations = this.getFilteredStations( this.props.dataExplorer.stations );
        //console.log( filteredStations );
        filteredStations = this.filterTimeRange( filteredStations );
        //console.log( filteredStations );

        let stationDropdown = this.getStationSelectbox(filteredStations);

        //setup the date restriction
        const today = new Date();
        let disableAfter = today;
        let disableBefore = null;

        const from = this.props.dataExplorer.dateTime.range.from;
        const to = this.props.dataExplorer.dateTime.range.to;

        let dateTimeSelectorCss = "";
        if( this.props.dataExplorer.contactFields.notes.showMessage.timeSelect !== "" ) {
            dateTimeSelectorCss = "invalid";    
        } else {
            dateTimeSelectorCss = "";
        }
        
        const currentYear = new Date().getFullYear();
        const fromMonth = new Date(currentYear - 20, 12);
        const toMonth = new Date(currentYear, 12);

        // adds special classes for the date picker from and to
        const datePickerModifiers =  {
            dateFrom: from,
            dateTo: to,
        };

        let prevMonth = new Date();
		prevMonth.setDate(1);
        prevMonth.setMonth(prevMonth.getMonth() - 1);

        let currentSelectedDate = null;
        if(this.props.dataExplorer.currentMonthFromDropdown !== undefined) {
            currentSelectedDate = this.props.dataExplorer.currentMonthFromDropdown;
        }else {
            if( to ) {
                currentSelectedDate = to;
            } 
            
            if( from ) {
                currentSelectedDate = from;
            } 
        } 

        return(
            <div className="slideout-panel-wrap data-explorer">
                <Helmet>
                    <title>{ pageTitle('Data Explorer') }</title>
                </Helmet>

                <Preloader showPreloader={ this.props.handleTogglePreloader } />
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }	
                />
                <SlideoutPanel 
                    onSlideoutClick={ this.toggleSlideout}
                    doSlideoutPanel={ this.props.dataExplorer.slideoutOpen }
                    menuData={ 
                        <div>
                            { this.getSelectedGaugesSection() }
                            <CheckboxList title="Filters"  handleInputChange={this.handleFilterChange} groupName="gauge-filters" checkboxList={filters} />
                        </div>
                    }
                />
                <div className="slideout-main-content-wrap" role="main">
                    <div className="site-content-wrap  clear" role="region" aria-labelledby="date-picker-region">
                        <h2 className="screen-reader-text" id="date-picker-region">Date picker for gauge data </h2>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1 id="main-heading" className="page-title">Data Explorer</h1>

                                    <div className={`form-field date-time-selector-container ${( this.props.dataExplorer.contactFields.notes.showMessage.timeSelect !== '' ? 'form-field-validation-error' : '' )}`}>
                                        <DayPicker 
                                            className={ dateTimeSelectorCss }
                                            numberOfMonths={2}
                                            selectedDays={[from, { from, to }]}
                                            onDayClick={this.handleDayClick}
                                            initialMonth={ prevMonth }
                                            fixedWeeks
                                            disabledDays={{ after: disableAfter, before: disableBefore } }
                                            month={ currentSelectedDate }
                                            fromMonth={ fromMonth }
                                            toMonth={ toMonth }
                                            modifiers={datePickerModifiers}
                                            captionElement={({ date, localeUtils }) => (
                                                <YearToForm
                                                    fromMonth={ fromMonth }
                                                    toMonth={ toMonth }
                                                    date={ date }
                                                    localeUtils={localeUtils}
                                                    onChange={this.handleYearMonthChange}
                                                />
                                            )}
                                        />
                                        <span className="validation-message">{ this.props.dataExplorer.contactFields.notes.showMessage.timeSelect }</span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>


                    <DataExplorerMap 
                        stations={filteredStations}
                        addGauges={this.addGauges}
                        removeGauges={this.removeGauges}
                    />

                    <div className="site-content-wrap clear" role="region" aria-labelledby="contact-info-region">
                        <h2 className="screen-reader-text" id="contact-info-region">Contact information</h2> 
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3>Stations</h3>

                                    <div className={`form-field ${( this.props.dataExplorer.contactFields.notes.showMessage.stationSelect !== '' ? 'form-field-validation-error' : '' )}`} role="search">
                                        { stationDropdown }
                                        <span className="validation-message">{ this.props.dataExplorer.contactFields.notes.showMessage.stationSelect }</span>
                                    </div>

                                    { this.getContactForm() }

                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );

        //this.setState({ doCloseConfirmation: false });

    }

    renderThankYouPage(){
        return (
            <PageWrapper>
                <h2>Thank you for submitting your request.</h2>
                <p>TRCA Staff will contact you directly to confirm. Please allow 5-10 days for data processing.</p><p>For self-serve data, please visit TRCA’s <a href="https://trca.ca/about/open/" target="_blank" rel="noopener noreferrer">Open Data Portal</a>.</p>
                <p><a href="/data-explorer" >Back</a></p>
            </PageWrapper>
        )

    }

    handleBackToExplorer() {
        this.setState({ submissionSuccess: false });
    }

    render() {

        if( this.state.submissionSuccess ) {
            return this.renderThankYouPage();
        } else {
            return this.renderSubmissionPage();
        }

    }
}

function mapStateToProps(state) {
    //console.log(state);
    return { 
        handleTogglePreloader: state.togglePreloader,
        dataExplorer: state.dataExplorer,
        fetchErrorMsg: state.fetchErrorMsg,
        userInfo: state.auth,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        togglePreloader: togglePreloader,
        setSlideoutOpen: setSlideoutOpen,
        updateAllStations: updateAllStations,
        updateAllFilters: updateAllFilters,
        setFilter: setFilter,
        selectGauges: selectGauges,
        deselectGauges : deselectGauges,
        deselectAllGauges : deselectAllGauges,
        selectAllGauges : selectAllGauges,
        updateContactFormField: updateContactFormField,
        validateAllFields: validateAllFields,
        submittingRequest : submittingRequest,
        submitSuccess: submitSuccess,
        submitFail: submitFail,
        handleDataExplorerTimeRangeSelection: handleDataExplorerTimeRangeSelection,
        resetDataExplorerData: resetDataExplorerData,
        setMonthForDatepickerDropdown: setMonthForDatepickerDropdown,
        showFetchError: showFetchError,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( DataExplorerContainer ); 
