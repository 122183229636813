import React, { Component } from "react";
import RadioButtonSeries from "./RadioButtonSeries";
import WidgetSizeLabel from "./WidgetSizeLabel";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";
import { getRoute, apiRequest } from "../../helpers/Api";

class DashboardMapModalContent extends Component { 
    constructor ( props ) {
        super( props ); 

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.getMapContent = this.getMapContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fetchMapData = this.fetchMapData.bind(this);
        
       if( props.editModeData ) {

            this.state = {
                widgetDisplayType: null,
                widgetSize: props.editModeData.options.size,
                timeframeValid: true,
                displayTypeValid: true,
                sizeValid: true,
                selectedMapType: props.editModeData.selectedMapType,
            };
        } else {
            this.state = {
                widgetDisplayType: null,
                widgetSize: null,
                timeframeValid: true,
                displayTypeValid: true,
                sizeValid: true,
                selectedMapType: null,
            };
        }

    }

    componentDidMount() { 
        this.fetchMapData();
    }

    componentWillReceiveProps( nextProps ) {
    }

    handleRadioInputChange( value, name, event ) {
        //console.log( value );

        switch( name ) {

            case "display-type":
            this.setState({selectedMapType: value, displayTypeValid: true});
            break;

            case "widget-size":
            this.setState({widgetSize: value, sizeValid: true});
            break;

            default:
            console.log( "An unknown radio button type was selected" );
            break;
        }
    }

    handleCheckboxInputChange(e) {
        //console.log(e.target.value); 
    }

    dropdownSelectionChanged(e, data) {
        this.setState({station: data.value});
    }

    addWidgetToDashboard() {
        let isFormValid = this.validateForm();
        let widgetObj = {};
        let widgetSize = null;

        switch( this.state.widgetSize ) {
            case "large-widget":
            widgetSize = "large";
            break;
            case "medium-widget":
            widgetSize = "medium";
            break;
            case "small-widget":
            widgetSize = "small";
            break;
            case "small":
            widgetSize = "small";
            break;
            case "medium":
            widgetSize = "medium";
            break;
            case "large":
            widgetSize = "large";
            break;
            default:
            console.log( "An unknown widget size was selected" );
            break;

        }

        if( isFormValid ) {
            let widgetToUpdate = null;

            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }

            widgetObj = {
                type: "station-map",
                order: "TODO",
                widgetToUpdate: widgetToUpdate,
                selectedMapType: this.state.selectedMapType,
                options: {
                    type: this.state.widgetDisplayType,
                    size: widgetSize,
                }
            }; 

            this.props.addWidgetToScreen( widgetObj );
        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        
        if(!this.state.selectedMapType) {
            formValid = false;
            this.setState({ displayTypeValid: false });
        } else {
            this.setState({ displayTypeValid: true });
        }

        if(!this.state.widgetSize) {
            formValid = false;
            this.setState({ sizeValid: false });
        } else {
            this.setState({ sizeValid: true });
        }

        return formValid;
    }

    getMapContent() {
        let displayTypes = [];
       
        displayTypes = [
            {label: "Precipitation Map", value: "precip", id: "precip-map"},
            {label: "Standard Station Map", value: "standard", id: "standard-map"},
        ];

        for( var j = 0; j < displayTypes.length; j++ ) {
            if( displayTypes[j].value === this.state.selectedMapType ) {
                displayTypes[j].checked = true;
            } else {
                displayTypes[j].checked = false;
            }
        }
        
        let displaySizes = [
            //{label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium-widget", id: "medium"},
            {label: <WidgetSizeLabel size="medium" label="medium"/>, value: "medium", id: "medium"},
            {label: <WidgetSizeLabel size="large" label="Large"/>, value: "large", id: "large"},
        ];


        for( let j = 0; j < displaySizes.length; j++ ) {
            if( displaySizes[j].id === this.state.widgetSize ) {
                displaySizes[j].checked = true;
            } else {
                displaySizes[j].checked = false;
            }
        }

        //add map widget components to modal
        let mapContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } STATION MAP</h2> 
            <RadioButtonSeries 
                title="Select type of display:"
                groupName="display-type"
                options={displayTypes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.displayTypeValid}
                shouldGetFocus={true}
                requiredValidationMessage="Please select a display type"
            />
            <RadioButtonSeries 
                title="Select a size:"
                groupName="widget-size"
                iconLabel={true}
                options={displaySizes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.sizeValid}
                requiredValidationMessage="Please select a display size"
            />

        </div>

        return mapContent;
    }

    fetchMapData() {
        let url = getRoute("stationAndGaugeNames");
        
        this.props.handleTogglePreloader(true);
        apiRequest(url)
		.then(result => {
            this.props.handleTogglePreloader(false);
            this.props.handleOpenModal();
            this.setState({ stationData: result });
        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
            
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
    }

    render() {
        let mapContent = null;

        if( this.state.stationData ) {
            mapContent = this.getMapContent();
        }

        return(
            <div className="dashboard-add-widget-modal-content"  >
                { mapContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardMapModalContent;